import Vue from 'vue';
import Vuex from 'vuex';
import Storage from '../vue-components/helpers/Storage.js'
import Network from "../vue-components/helpers/Network.js";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        applicationReady: false,
        websiteAlias: `hopital-lakeshore`,
        page: null,
        website: null,
        cms: null,
        user: null,
        env: localStorage.getItem(`env`) || `dev`,
        isKioskLayout: false,
        isMobileLayout: false,
        isSmallLayout: false,
        isMobileOrTabletLayout: false,
        isLargeLayout: false,
        isExtraLargeLayout: false
    },
    getters: {
        cms(state) {
            return state.cms ? state.cms : null;
        },
        currentPage(state) {
            return state.page ? state.page : null;
        },
        currentWebsite(state) {
            return state.website ? state.website : null;
        },
        currentUser(state) {
            return state.user ? state.user : null;
        },
        isAuthenticated(state) {
            return !!state.user;
        },
        env(state) {
            return state.env;
        }
    },
    mutations: {
        setCms(state, cms) {
            state.cms = cms;
        },
        setPage(state, page) {
            state.page = page;
        },
        setWebsite(state, website) {
            state.website = website;
        },
        startLogin(state) {
            state.loginStatus = `loading`
        },
        authSuccess(state, user) {
            state.loginStatus = `success`;
            state.user = user
        },
        notConnected(state) {
            state.loginStatus = `not_connected`;
        },
        authError(state) {
            state.loginStatus = `error`
        },
        loggedOut(state) {
            state.loginStatus = `logged_out`;
            state.user = null
        }
    },
    actions: {
        loadPage({state, commit}, alias) {
            return new Promise((resolve, reject) => {
                if (state.website) {
                    for (let page of state.website.pages) {
                        if (page.alias.en === alias || page.alias.fr === alias) {
                            commit(`setPage`, page);
                            return resolve(page);
                        }
                    }
                }
                commit(`setPage`, null);
                reject();
            });
        },
        getCurrentUser({commit}) {
            return new Promise((resolve, reject) => {
                commit(`startLogin`);
                Storage.get(`token`)
                    .then(token => {
                        if (token) {
                            Network.setToken(token);

                            Network.get(`/api/auth/current-user`)
                                .then(response => {
                                    if (response.data) {
                                        commit(`authSuccess`, response.data);
                                        resolve();
                                    }
                                })
                                .catch(err => {
                                    commit(`authError`, err);
                                    Storage.remove(`token`).then();
                                    Network.removeToken();
                                    reject(err);
                                });
                        } else {
                            commit (`notConnected`);
                            resolve();
                        }
                    })
                    .catch(() => {
                        resolve();
                    });
            });
        },
        login({commit}, options) {
            return new Promise((resolve, reject) => {
                commit(`startLogin`);
                Network.post(options.url, options.params)
                    .then(response => {
                        const user = response.data;
                        Network.setToken(user.token);
                        commit(`authSuccess`, user);
                        resolve(user);
                    })
                    .catch(err => {
                        commit(`authError`, err);
                        Storage.remove(`token`).then();
                        Network.removeToken();
                        reject(err);
                    });
            });
        },
        logout({commit}) {
            return new Promise(resolve => {
                commit(`loggedOut`);
                Storage.remove(`token`).then();
                Network.removeToken();
                resolve();
            });
        }
    },
    modules: {}
});
