import MapBaseClass from "./MapBaseClass.js";
import MapFloor from "./MapFloor.js";
import MapItem from "./MapItem.js";
import MapLink from "./MapLink.js";

export default class MapPlan extends MapBaseClass {
    constructor(data) {
        super(data);
        /*

        this.dealerid = `HopitalLakeshore`;
        this.name = `Lakeshore Wayfinder`;
        this.details = null;
        this.map_type = `indoor`;
        this.floor_index = 0;
        this.scale = null;
        this.icon_size = 0;
        this.is_default_map = true;
        this.blueprint = false;
        this.style = null;
        this.image_file_name = null;
        this.image_full_path = `https://eyeindevstorage.blob.core.windows.net/eye-intelligence/maps/null`;
        this.thumbnail = null;
        this.default_equipment_ip = null;
        this.date_creation = `2024-03-07T15:12:08.897Z`;
        this.date_edition = `2024-03-07T15:12:08.897Z`;
        this.latitude = null;
        this.longitude = null;
        this.hierarchy = null;
        this.geolocation_technology = null;
        this.process_daily_stats = false;
        this.filter_geolocation_stats = false;
        this.real_width = null;
        this.real_height = null;
        this.privacy = `private`;
        this.deleted = false;
         */

        // for autocompletion
        this.floors = [];
        this.items = [];
        this.links = [];
        for (let i = 0; i < data.floors.length; i++) {
            this.floors.push(new MapFloor(data.floors[i]));
        }
        for (let i = 0; i < data.items.length; i++) {
            let mapItem = new MapItem(data.items[i], this);
            mapItem.index = `n${i}`;
            this.items.push(mapItem);
        }
        // Always initialize links after items
        for (let i = 0; i < data.links.length; i++) {
            let mapLink = new MapLink(data.links[i], this);
            mapLink.index = `l${i}`;
            this.links.push(mapLink);
        }

        if (this.floors.length > 1) {
            this.sortFloors();
        }

        this.style = clone(MapPlan.getDefaultStyle());
    }

    static getDefaultStyle() {
        return {
            backgroundOpacity: 0.8,
            iconSize: 42,
            iconCircleVisible: false,
            theme: `light`,
            skin: `default`,
            defaultZoom: 1,
            uiScale: 1.8
        }
    }

    getFloorById(id) {
        return this.floors.find(f => f.id === id);
    }

    getItemById(id) {
        return this.items.find(i => i.id === id);
    }

    getMainFloor() {
        if (this.floors.length === 0) {
            return null;
        } else if (this.floors.length === 1) {
            return this.floors[0];
        } else {
            const floor = this.floors.find(floor => {
                return floor.main_floor === true;
            });
            if (floor) {
                return floor;
            } else {
                return this.floors[0];
            }
        }
    }

    addFloor(floor) {
        this.floors.push(floor);
        this.sortFloors();
    }

    addLink(link) {
        this.links.push(link);
    }

    addItem(link) {
        this.items.push(link);
    }

    sortFloors() {
        this.floors.sort((a, b) => {
            return b.floor - a.floor;
        });
    }

    getSave() {
        let data = {
            floors: [],
            items: [],
            links: []
        };
        for (let key in this) {
            switch (key) {
                case `floors`:
                    for (let floor of this.floors) {
                        if (!floor.deleted) {
                            data.floors.push(floor.getSave());
                        }
                    }
                    break;
                case `items`:
                    for (let item of this.items) {
                        data.items.push(item.getSave());
                    }
                    break;
                case `links`:
                    for (let link of this.links) {
                        data.links.push(link.getSave());
                    }
                    break;
                default:
                    if (typeof this[key] !== `function`) {
                        data[key] = this[key];
                    }
                    break;
            }
        }
        console.log(data);
        return data;
    }
}
