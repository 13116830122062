export default {
    computed: {
        showMapDebug() {
            return this.$store.state.mapEditor.debug === true;
        },
        currentMap() {
            return this.$store.state.mapEditor.map;
        },
        // currentFloor() {
        //     return this.$store.state.mapEditor.floor;
        // }
    },
    methods: {
        scaleUI(sizeInPixel) {
            return this.$store.state.mapEditor.map.style.uiScale * (sizeInPixel * this.$store.state.mapEditor.floor.real_width / this.$store.state.mapEditor.width) / this.$store.state.mapEditor.zoom;
        },
        pxToMeters(pxCoordinate) {
            return pxCoordinate * this.$store.state.mapEditor.floor.scale / 100;
        },
        metersToPx(mCoordinate) {
            return mCoordinate / this.$store.state.mapEditor.floor.scale * 100;
        }
    }
}
