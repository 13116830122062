<template>
    <website-page>
        <h1 class="mt-3 mb-4" v-tr>Welcome|Bienvenue</h1>

        <e-container class="mb-4" :max-width="800">
            <div v-show="mainTab === TABS.HOME">
                <b-row>
                    <b-col cols="12" md="6">
                        <b-card @click="setTab(TABS.CHECK_IN)" img-src="https://eyeindevstorage.blob.core.windows.net/eye-intelligence/cms/medias/hopitallakeshore/hospital-card.jpg">
                            <b-btn variant="primary" block>
                                <h3 class="m-0" v-tr>Check-in|M'enregistrer</h3>
                            </b-btn>
                            <h5 class="mt-3 mb-0" v-tr>If you already have your Hospital Card|Si vous avez déjà votre carte d'hôpital</h5>
                        </b-card>
                    </b-col>
                    <b-col cols="12" md="6">
                        <b-card @click="$router.push(`/map/?end=4EA5026F-F1E7-4580-9FB5-3CEABFEB914A`)" img-src="https://eyeindevstorage.blob.core.windows.net/eye-intelligence/cms/medias/hopitallakeshore/department-admission.jpg">
                            <b-btn variant="info" block>
                                <h3 class="m-0" v-tr>Request a Card|Demander une carte</h3>
                            </b-btn>
                            <h5 class="mt-3 mb-0" v-tr>If you don't have your Hospital Card.|Si vous n'avez pas votre carte d'hôpital</h5>
                        </b-card>
                    </b-col>
                </b-row>
            </div>

            <b-card v-show="mainTab === TABS.CHECK_IN">

                <i class="fal fa-address-card fa-4x mt-4"/>

                <h2 v-tr>Please enter your Hospital ID|Merci d'entrer votre identifiant</h2>

                <e-input :minlength="9" required maxlength="9" v-model="idCard" class="mx-auto" :placeholder="'ex: U-1234567'|tr" style="padding: 20px 20px; font-size: 18pt; width: 250px; text-transform: uppercase"/>

                <template #footer>
                    <div class="w-100 text-right">
                        <b-btn class="float-left" variant="dark" @click="setTab(TABS.HOME)">
                            <i class="fas fa-chevron-left mr-1"/>
                            <span v-tr>Cancel|Annuler</span>
                        </b-btn>
                        <b-btn variant="primary" @click="checkIn()">
                            <span v-tr>Check-In|M'enregistrer</span>
                            <i class="fas fa-chevron-right ml-1"/>
                        </b-btn>
                    </div>
                </template>
            </b-card>

            <b-card class="mt-4 text-left">
                <template #header>
                    <h4 class="my-0">
                        <i class="fas fa-info-circle mr-2"/>
                        <span v-tr>Help|Aide</span>
                    </h4>
                </template>
                <h4 v-tr>What is a Hospital Card?|Qu'est-ce qu'une carte d'hôpital ?</h4>
                <p v-tr>La carte d'hôpital est une carte spéciale du CIUSSS de l'Ouest-de-l'île-de-Montréal. Elle est différente de votre carte d'assurance maladie.</p>
                <hr>
                <h4 v-tr>Where to find my ID Number|Où se trouve mon numéro identifiant?</h4>
                <p v-tr>Votre numéro identifiant se trouve en haut à gauche de la carte et commence par une lettre, un tiret et 7 chiffres (ex: U-1234567)</p>
                <b-img class="mt-2" width="500" src="https://eyeindevstorage.blob.core.windows.net/eye-intelligence/cms/medias/hopitallakeshore/hospital-card-id.png" style="box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25); border-radius: 12px; max-width: 100%"/>
                <hr>
                <h4 v-tr>How to get my Hospital Card?|Comment obtenir ma carte d'hôpital ?</h4>
                <p v-tr>Cette carte est gratuite. Présentez-vous au bureau des admissions proche de l'entrée principale, local 1286. Vous aurez besoin de votre carte d'assurance maladie valide</p>
                <hr>
                <h4 v-tr>Need more help?|Besoin d'aide?</h4>
                <p v-tr>Allez au bureau des admissions proche de l'entrée principale, local 1286</p>
                <b-btn class="mt-3" variant="primary" to="/map/?end=4EA5026F-F1E7-4580-9FB5-3CEABFEB914A">
                    <i class="fas fa-map-location-dot mr-1"/>
                    <span v-tr>View Admission Office|Trouver le bureau des admissions</span>
                </b-btn>
            </b-card>
        </e-container>

        <template v-if="isKioskLayout" #keyboard>
            <virtual-keyboard v-show="mainTab === TABS.CHECK_IN" ref="keyboard" v-model="idCard" :max-length="9" @change="onKeyboardChange"/>
        </template>
    </website-page>
</template>

<script>
import EInput from "../../vue-components/components/e-input.vue";
import VirtualKeyboard from "../components/virtual-keyboard.vue";

export default {
    name: `check-in`,
    components: {EInput, VirtualKeyboard},
    data() {
        return {
            TABS: {
                HOME: `home`,
                CHECK_IN: `check-in`,
                CREATE_CARD: `create-card`
            },
            mainTab: `home`,
            idCard: ``
        }
    },
    activated() {
        this.mainTab = this.TABS.HOME;
        this.idCard = ``;
    },
    methods: {
        setTab(tab) {
            this.mainTab = tab;
        },
        checkIn() {
            this.showSavedMessage(`Checked-In|Enregistré`, `You have been checked-in successfully.|Vous avez été enregistré avec succès.`);
            this.idCard = ``;
            this.setTab(this.TABS.HOME);
        },
        onKeyboardChange(input) {
            this.idCard = input;
            if (this.idCard.length >= 9) {
                this.idCard = this.idCard.substring(0, 9);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
