<template>
    <svg ref="svg" class="e-interactive-svg" :view-box.camel="viewBox"
         @touchmove="onMouseMove" @touchstart="startDragging" @touchend="stopDragging"
         @mousemove="onMouseMove" @mousewheel="onMouseWheel" @mousedown="startDragging" @mouseup="stopDragging" @mouseleave="stopDragging"
         xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <g class="main-container" :transform="transformMatrix">
            <image @mousedown.self="$emit('unselect')" v-if="backgroundImage" :href="backgroundImage" :width="realWidth" :height="realHeight" x="0" y="0" :opacity="opacity"></image>
            <slot></slot>

            <slot name="pointer" v-bind="{ x: mouseCoordinates.x, y: mouseCoordinates.y }"></slot>

            <!-- Debug tool only -->
<!--            <circle style="pointer-events: none" :cx="mouseCoord.x" :cy="mouseCoord.y" :r="0.5 / scale"></circle>-->
        </g>
        <g class="scale-legend" v-if="showScaleLegend">
            <text class="scale-title" :x="scaleLine.x1" :y="scaleLine.y1 - scaleLine.strokeWidth * 2" fill="white" style="font-size: 6px">{{scaleLine.unit}}m</text>
            <line class="scale-bar" :x1="scaleLine.x1" :y1="scaleLine.y1" :x2="scaleLine.x2" :y2="scaleLine.y2" :stroke-width="scaleLine.strokeWidth" stroke="white"></line>
        </g>
    </svg>
</template>

<script>
    import {identity, multiply, matrix, inv} from 'mathjs'

    export default {
        name: `e-interactive-svg`,
        props: {
            itemSelected: { type: Object },
            zoom: { type: [Number] },
            realWidth: { type: Number, required: true },
            realHeight: { type: Number, required: true },
            showScaleLegend: {type: Boolean},
            opacity: { type: Number, default: 0.5 },
            backgroundImage: String
        },
        data() {
            return {
                matrix: identity(3),
                refPoint: null,
                svgWidth: 0,
                svgHeight: 0,
                offsetX: 0,
                offsetY: 0,
                scale: 1,
                mouseCoordinates: {x: 0, y: 0},
                lastMouseCoordinates: {x: 0, y: 0},
                dragging: false,
                dragItemCoordinates: {x: 0, y: 0},
                svgInversedMatrix: null
            }
        },
        computed: {
            viewBox() {
                return `0 0 ${this.realWidth} ${this.realHeight}`
            },
            transformMatrix() {
                return `matrix(${this.matrix._data[0][0]},${this.matrix._data[1][0]},${this.matrix._data[0][1]},${this.matrix._data[1][1]},${this.matrix._data[0][2]},${this.matrix._data[1][2]})`;
            },
            scaleLine() {
                let ratio = this.realWidth / this.svgWidth;
                if (!this.svgWidth) {
                    ratio = 1;
                }
                const x1 = 10 * ratio;
                let unit = 100; // scale is 100m
                if (1000 * this.scale / ratio < 100) {
                    unit = 1000; // scale is 1000m
                } else if (100 * this.scale / ratio < 100) {
                    unit = 100; // scale is 100m
                } else if (10 * this.scale / ratio < 100) {
                    unit = 10; // scale is 10m
                } else if (this.scale / ratio < 100) {
                    unit = 1; // scale is 1m
                }
                return {
                    x1: x1,
                    y1: this.realHeight - 10 * ratio,
                    x2: unit * this.scale + x1,
                    y2: this.realHeight - 10 * ratio,
                    strokeWidth: 2 * ratio,
                    unit: unit
                }
            }
        },
        mounted() {
            this.applySizeChange();
            window.addEventListener(`resize`, this.applySizeChange);
        },
        beforeDestroy() {
            window.removeEventListener(`resize`, this.applySizeChange);
        },
        methods: {
            applySizeChange() {
                this.refPoint = this.$refs.svg.createSVGPoint();
                const bbox = this.$refs.svg.getBBox();
                this.svgWidth = bbox.width;
                this.svgHeight = bbox.height;
                this.svgInversedMatrix = this.$refs.svg.getScreenCTM().inverse()
            },
            applyTranslate(tx, ty) {
                const translationMatrix = matrix([
                    [1, 0, tx],
                    [0, 1, ty],
                    [0, 0, 1]
                ]);
                this.matrix = multiply(translationMatrix, this.matrix);
                this.offsetX += tx;
                this.offsetY += ty;
            },
            applyScale(s) {
                const scaleMatrix = matrix([
                    [s, 0, 0],
                    [0, s, 0],
                    [0, 0, 1]
                ]);
                this.matrix = multiply(scaleMatrix, this.matrix);
                this.scale *= s;
                this.$emit(`update:zoom`, this.scale);
            },
            mouseEventToPoint(event) {
                this.refPoint.x = event.clientX;
                this.refPoint.y = event.clientY;
                const coordinates = this.refPoint.matrixTransform(this.svgInversedMatrix);

                const result = multiply(inv(this.matrix), matrix([
                    [coordinates.x],
                    [coordinates.y],
                    [1]
                ]));

                return {
                    x: result._data[0][0],
                    y: result._data[1][0]
                };
            },
            startDragging(event) {
                // start dragging
                this.mouseCoordinates = this.mouseEventToPoint(event);
                if (event.button === 0 || event.type === `touchstart`) {
                    this.dragging = true;
                    if (event.type === `touchstart`) {
                        event = event.touches[0];
                    }
                    this.lastMouseCoordinates = {x: event.clientX, y: event.clientY};

                    if (this.itemSelected) {
                        this.dragItemCoordinates.x = this.itemSelected.x;
                        this.dragItemCoordinates.y = this.itemSelected.y;
                    }
                }
            },
            stopDragging() {
                this.dragging = false
            },
            onMouseMove(event) {
                if (event.type === `touchmove`) {
                    event = event.touches[0];
                    // event.clientY = event.touches[0].clientY;
                }

                this.mouseCoordinates = this.mouseEventToPoint(event);
                if (this.dragging) {
                    this.refPoint.x = event.clientX;
                    this.refPoint.y = event.clientY;
                    const mousePoint = this.refPoint.matrixTransform(this.svgInversedMatrix);

                    this.refPoint.x = this.lastMouseCoordinates.x;
                    this.refPoint.y = this.lastMouseCoordinates.y;
                    const coordinates = this.refPoint.matrixTransform(this.svgInversedMatrix);

                    const translationX = coordinates.x - mousePoint.x;
                    const translationY = coordinates.y - mousePoint.y;
                    if (!this.itemSelected) {
                        this.applyTranslate(-translationX, -translationY);
                    } else {
                        this.$emit(`item-moved`, this.mouseCoordinates);
                    }
                }
                this.lastMouseCoordinates = {x: event.clientX, y: event.clientY};
                event.svgX = this.mouseCoordinates.x;
                event.svgY = this.mouseCoordinates.y;
                this.$emit(`mousemove`, this.mouseCoordinates);
            },
            onMouseWheel(event) {
                this.refPoint.x = event.clientX;
                this.refPoint.y = event.clientY;
                const centerOfZoom = this.refPoint.matrixTransform(this.svgInversedMatrix);
                const scaleRatio = event.deltaY > 0 ? 0.9 : 1.1;
                this.applyTranslate(-centerOfZoom.x, -centerOfZoom.y);
                this.applyScale(scaleRatio);
                this.applyTranslate(centerOfZoom.x, centerOfZoom.y);
            },
            applyZoom(zoomDelta = 1) { // 1 or -1
                this.refPoint.x = window.innerWidth / 2;
                this.refPoint.y = window.innerHeight / 2;
                const centerOfZoom = this.refPoint.matrixTransform(this.svgInversedMatrix);
                const scaleRatio = zoomDelta > 0 ? 1.25 : 0.75;
                this.applyTranslate(-centerOfZoom.x, -centerOfZoom.y);
                this.applyScale(scaleRatio);
                this.applyTranslate(centerOfZoom.x, centerOfZoom.y);
            }
        },
        watch: {
            realWidth() {
                this.applySizeChange();
            },
            realHeight() {
                this.applySizeChange();
            }
        }
    }
</script>

<style lang="scss" scoped>
.e-interactive-svg {
    width: 100%;
    height: 100%;

    .scale-title {
        alignment-baseline: middle;
        user-select: none;
        fill: white;
        text-align: left;
        font-weight: 500;
        text-shadow: 2px 2px 5px black;
    }

    .scale-bar {
        filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5));
    }
}
</style>
