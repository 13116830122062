<template>
    <website-page>
        <e-container v-if="item">
            <sub-navigation-bar :title="item.name" :back-to="currentPage.back.to" :back-text="currentPage.back.text"/>

            <b-img v-if="item.coverImage" class="cover-image" :src="item.coverImage"/>

            <div class="pb-4">
                <slot>
                    <component :is="componentType" :item="item"/>
                </slot>
            </div>
        </e-container>
    </website-page>
</template>

<script>
import DepartmentDetails from "../components/cms/details/department-details.vue";
import SubNavigationBar from "../layout/sub-navigation-bar.vue";

export default {
    name: `cms-item-details-page`,
    components: {SubNavigationBar, DepartmentDetails},
    props: {
        currentPage: { type: Object, required: true }
    },
    data() {
        return {
            loading: false,
            item: null
        }
    },
    computed: {
        componentType() {
            return `department-details`;
        }
    },
    mounted() {
        this.loadItem();
    },
    methods: {
        loadItem() {
            this.item = this.cms.getItemById(this.$route.params.pathMatch);
        },
    },
    watch: {
        currentPage() {
            this.loadItem();
        }
    }
}
</script>

<style lang="scss" scoped>
.cover-image {
    width: 100%;
    height: 250px;
    object-fit: cover;
}
</style>
