import {tr} from "../../../vue-components/helpers/i18n.js";

export default class CmsItem {
    /**
     *
     * @param data {Object}
     * @param typeDef {CmsType}
     */
    constructor(data, typeDef) {
        this.id = generateGuid();
        for (let key in data) {
            this[key] = data[key];
        }
        if (typeof this.name === `object`) {
            this.name = tr(this.name);
        }

        /**
         *
         * @type {CmsType}
         */
        this.typeDef = typeDef;
        if (!this.typeDef) {
            console.error(`Invalid item type for ${this.name}`);
        } else {
            if (typeDef.displayId) {
                this.name = `${this.id} - ${this.name}`;
            }
        }
    }

    getSecondaryValue() {
        if (!this.typeDef.secondaryField) {
            return null;
        }
        const parents = this.typeDef.secondaryField.split(`.`);
        let value = this;
        for (let parent of parents) {
            value = value[parent];
        }
        return value;
    }
}
