<template>
    <div class="department-details">
        <b-card v-if="item" class="my-3">
            <b-media>
                <template #aside>
                    <i class="fa-3x pt-2" :class="[fontAwesomePrefix, item.typeDef.glyph]"/>
                </template>
                <h2 class="title">{{item.name}}</h2>
                <div v-if="item.headline" class="subtitle">{{item.headline}}</div>
                <div v-else class="subtitle">{{item.typeDef.name}}</div>

                <p class="description" v-if="item.description">{{item.description}}</p>

                <b-btn v-if="item.phoneNumber" size="lg" class="mb-3" variant="white">
                    <i class="fal fa-phone"/> {{item.phoneNumber}} <span v-if="item.extension">poste {{item.extension}}</span></b-btn>

                <b-btn variant="primary" size="lg" :class="isMobileLayout ? 'mb-3' : 'mr-3'" :block="isMobileLayout" v-if="mapLink" @click="hide" :to="mapLink">
                    <i class="fal fa-map-location mr-2"/>
                    <span v-tr>View on Map|Afficher sur le plan</span>
                </b-btn>
            </b-media>
        </b-card>

        <b-row>
            <b-col cols="12" md="6">
                <b-card no-body>
                    <template #header>
                        <h2 class="title">
                            <i :class="fontAwesomePrefix" class="fa-user-doctor mr-1"/>
                            <span v-tr>Employees|Employés</span>
                        </h2>
                    </template>
                    <b-list-group class="category-items no-borders">
                        <search-list-item v-for="item in employees" :key="'item-' + item.id" :item="item" @click="selectItem(item)"/>
                    </b-list-group>
                </b-card>
            </b-col>
            <b-col cols="12" md="6">
                <b-card no-body>
                    <template #header>
                        <h2 class="title">
                            <i :class="fontAwesomePrefix" class="fa-house-medical mr-1"/>
                            <span v-tr>Rooms|Salles</span>
                        </h2>
                    </template>
                    <b-list-group class="category-items no-borders">
                        <search-list-item v-for="item in rooms" :key="'item-' + item.id" :item="item" @click="selectItem(item)"/>
                    </b-list-group>
                </b-card>
            </b-col>
        </b-row>

        <item-summary v-if="itemSelected" :item="itemSelected" @hidden="itemSelected = null" show-map-link/>
    </div>
</template>

<script>
import CmsItem from "../../../helpers/cms/CmsItem.js";
import CmsType from "../../../helpers/cms/CmsType.js";
import ItemSummary from "../item-summary.vue";
import SearchListItem from "../search-list-item.vue";

export default {
    name: `department-details`,
    components: {ItemSummary, SearchListItem},
    props: {
        item: {type: CmsItem, required: true}
    },
    data() {
        return {
            rooms: [],
            employees: [],
            itemSelected: null
        }
    },
    computed: {
        mapLink() {
            if (this.item.mapId) {
                return `/map?start=${this.item.mapId}`;
            } else if (this.item.department && this.item.department.mapId) {
                return `/map?start=${this.item.department.mapId}`;
            }
            return null;
        }
    },
    mounted() {
        this.loadItems();
    },
    methods: {
        loadItems() {
            this.rooms = this.cms.items.filter(i => i.type === CmsType.TYPES.ROOM && i.departmentId === this.item.id);
            this.employees = this.cms.items.filter(i => i.type === CmsType.TYPES.EMPLOYEE && i.departmentId === this.item.id);
        },
        selectItem(item) {
            this.itemSelected = item;
        }
    },
    watch: {
        item() {
            this.loadItems();
        }
    }
}
</script>

<style lang="scss" scoped>
.description {
    font-size: 15pt;
}
</style>
