<template>
    <component v-if="componentTag && value" :is="componentTag" class="website-item" :class="item.classes" :variant="item.variant"
               :to="item.to" :href="item.href" :target="item.target" :title="item.title|tr">
        <i v-if="item.icon && item.iconAlign === 'left'" :class="item.icon" class="fa-fw"/>
        {{item.name|tr}}
        <i v-if="item.icon && item.iconAlign === 'right'" :class="item.icon" class="fa-fw"/>
    </component>
</template>

<script>
export default {
    name: `website-item`,
    props: {
        value: { type: Object }
    },
    data() {
        return {
            componentTag: ``,
            item: {
                type: null,
                variant: null,
                classes: null,
                name: null,
                title: null,
                icon: null,
                iconAlign: `right`,
                href: null,
                to: null,
                target: null
            }
        }
    },
    mounted() {
        if (!this.value) {
            return false;
        }
        for (let key in this.item) {
            if (this.value[key] !== undefined) {
                this.item[key] = this.value[key];
            }
        }

        switch (this.item.type) {
            case `button`:
                this.componentTag = `b-btn`;
                break;
            case `link`:
                if (this.item.to) {
                    this.componentTag = `router-link`;
                } else {
                    this.componentTag = `a`;
                }
                break;
            default:
                if (this.item.to) {
                    this.componentTag = `router-link`;
                } else {
                    this.componentTag = `div`;
                }
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
