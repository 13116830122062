<template>
    <website-page>
        <div class="d-flex flex-column w-100 h-100 justify-content-center align-items-center">
            <img :src="require('../assets/icons/logo.png')"/>
            <h1 class="mt-3">Welcome to Eye-In CMS</h1>
            <p>This Website is not configured yet</p>
            <b-btn href="https://eye-in.com">Visit our Website</b-btn>
        </div>
    </website-page>
</template>

<script>
export default {
    name: `error-site-construction`
}
</script>

<style lang="scss" scoped>
</style>
