<template>
    <b-modal :title="item.typeDef.name" size="lg" :dialog-class="modalClass" :scrollable="isMobileLayout" v-model="visible" @hidden="hide"
             :centered="centered" :hide-header="!isMobileLayout" :hide-backdrop="hideBackdrop">
        <b-overlay :show="loading">
            <template v-if="item">
                <b-row>
                    <b-col cols="12" md="4" v-if="!isMobileLayout && item.coverImage">
                        <b-img fluid :src="item.coverImage"/>
                    </b-col>
                    <b-col cols="12" md="8">
                        <b-media>
                            <template #aside>
                                <i class="fa-3x pt-2" :class="[fontAwesomePrefix, item.glyph ? item.glyph : item.typeDef.glyph]"/>
                            </template>
                            <h2 class="title">{{item.name}}</h2>
                            <div v-if="item.headline" class="subtitle">{{item.headline}}</div>
                            <div v-else class="subtitle">{{item.typeDef.name}}</div>

                            <p class="description" v-if="item.description">{{item.description}}</p>
                            <b-btn v-if="item.phoneNumber" size="lg" class="mb-3" variant="white">
                                <i class="fal fa-phone"/> {{item.phoneNumber}} <span v-if="item.extension">ext {{item.extension}}</span></b-btn>
                        </b-media>
                    </b-col>
                </b-row>

                <b-card v-if="item.department || item.room">
                    <b-media v-if="item.department">
                        <template #aside>
                            <i class="fa-fw fa-2x pt-2" :class="[fontAwesomePrefix, item.department.glyph || item.department.typeDef.glyph]"/>
                        </template>
                        <div class="f-700" v-tr>Department|Département</div>
                        <h3 class="department">
                            {{item.department.name}}
                        </h3>
                    </b-media>
                    <template v-if="item.room">
                        <div class="f-700" v-tr>Room|Salle</div>
                        <h3 class="room">
                            {{item.room.name}}
                        </h3>
                    </template>
                </b-card>
            </template>
        </b-overlay>

        <template #modal-footer>
            <div class="w-100 text-right">
                <b-btn v-if="!isMobileLayout" @click="hide" size="lg" class="float-left">
                    <span v-tr>Close|Fermer</span>
                </b-btn>

                <template v-if="item">
                    <b-btn variant="primary" size="lg" :class="isMobileLayout ? 'mb-3' : 'mr-3'" :block="isMobileLayout" v-if="mapLink" @click="hide" :to="mapLink">
                        <i class="fal fa-map-location mr-2"/>
                        <span v-tr>View on Map|Afficher sur le plan</span>
                    </b-btn>

                    <b-btn variant="dark" size="lg" @click="hide" :block="isMobileLayout" v-if="itemDetailPage" :to="itemDetailPage">
                        <i class="fal fa-info-circle mr-2"/>
                        <span v-tr>Information|Informations</span>
                    </b-btn>
                </template>
                <b-btn variant="primary" size="lg" v-else :disabled="true">
                    <span class="mr-1" v-tr>Loading|Chargement</span>
                    <i class="fal fa-spinner-third fa-spin"/>
                </b-btn>
            </div>
        </template>
    </b-modal>
</template>

<script>
import CmsItem from "../../helpers/cms/CmsItem.js";

export default {
    name: `item-summary`,
    props: {
        item: {type: CmsItem, required: true},
        modalClass: {type: String},
        centered: {type: Boolean, default: true},
        hideBackdrop: {type: Boolean},
    },
    data() {
        return {
            loading: false,
            visible: true
        }
    },
    computed: {
        mapLink() {
            if (this.item.mapId) {
                return `/map?start=${this.item.mapId}`;
            } else if (this.item.department && this.item.department.mapId) {
                return `/map?start=${this.item.department.mapId}`;
            }
            return null;
        },
        itemDetailPage() {
            if (!this.item.typeDef.viewUrl) {
                return null;
            }
            return this.item.typeDef.viewUrl.replace(`{{id}}`, this.item.id);
        }
    },
    methods: {
        hide() {
            this.visible = false;
            this.$emit(`hidden`);
        }
    }
}
</script>

<style lang="scss" scoped>
.logo {
    width: 250px;
    height: 150px;

    object-fit: contain;
}

.description {
    font-size: 15pt;
}
</style>
