<template>
    <b-navbar toggleable="lg" :fixed="fixed ? 'top': ''" class="website-menu" :type="website.menu.design.theme" :variant="website.menu.design.variant" :style="website.menu.design.style">
        <b-container class="website-menu-container">
            <b-navbar-brand v-if="isMobileLayout" to="/" class="mobile-logo">
                <b-img :src="website.branding.logo.src" :alt="website.branding.logo.alt" :style="website.menu.logo.style"/>
            </b-navbar-brand>

            <b-navbar-toggle target="navbar-toggle-collapse" class="mobile-toggle">
                <template #default="{ expanded }">
                    <span>Menu <i v-if="expanded" class="fal fa-chevron-up"></i>
                        <i v-else class="fal fa-chevron-down"></i>
                    </span>
                </template>
            </b-navbar-toggle>

            <b-collapse id="navbar-toggle-collapse" is-nav>
                <b-navbar-nav class="w-100">
                    <b-navbar-brand v-if="!isMobileLayout" class="mr-auto" to="/">
                        <b-img :style="website.menu.logo.style" :src="website.branding.logo.src" :alt="website.branding.logo.alt"/>
                        <b-nav-text class="menu-title" v-if="website.menu.title.visible">
                            {{website.menu.title.text}}
                        </b-nav-text>
                    </b-navbar-brand>
                    <b-nav-item v-for="(link, index) in links" :key="`link-${index}`" :to="link.to" v-show="!link.kioskOnly">
                        <i v-if="link.icon" class="mr-1" :class="link.icon"/>
                        <span>{{link.name|tr}}</span>
                    </b-nav-item>
                    <b-nav-item @click="changeLanguage"><span v-tr>FR|EN</span></b-nav-item>
                </b-navbar-nav>
            </b-collapse>
        </b-container>
    </b-navbar>
</template>

<script>
import {changeLanguage} from "../../vue-components/helpers/i18n";

export default {
    name: `website-menu`,
    props: {
        fixed: {type: Boolean, default: false},
        loading: {type: Boolean, default: false}
    },
    data() {
        return {
            searchVisible: false,
            search: ``,
            loginModal: false
        }
    },
    computed: {
      links() {
          return this.website.menu.links.filter(section => {
              if (this.isMobileLayout) {
                  return (section.mobile !== false);
              } else if (this.isTabletLayout) {
                  return (section.tablet !== false);
              } else if (this.isLargeLayout) {
                  return (section.desktop !== false);
              }
              return true;
          });
      }
    },
    methods: {
        changeLanguage() {
            changeLanguage();
            this.$router.go();
        }
    }
}
</script>

<style lang="scss" scoped>
.website-menu {
    height: var(--menu-height);
    width: 100%;
    padding: 0;

    &:after {
        content: "";
        position: absolute;
        top: var(--menu-height);
        left: 0;
        right: 0;
        display: var(--menu-border-display);
        height: var(--menu-border-height);
        background: var(--menu-border-background);
        z-index: 1;
    }

    > hr {
        width: 100%;
        margin: 0;
        border-bottom: 1px solid rgba(white, 0.05);
        //border-top: 1px solid rgba(black, 0.5);
    }

    .website-menu-container {
        height: var(--menu-height);
        padding: 0.5rem 1rem;
        background-color: inherit;
    }

    .navbar-brand {
        padding: 0;
    }

    .mobile-logo {
        padding: 0;
    }

    .navbar-nav {
        .nav-link {
            position: relative;
            transition: 150ms;
            margin: var(--menu-item-margin);
            padding: var(--menu-item-padding);
            border-radius: var(--menu-item-border-radius);
            color: var(--menu-item-color);
            background: var(--menu-item-background);
            font-weight: var(--menu-item-font-weight);

            &:hover {
                background: var(--menu-item-hover-background);
                color: var(--menu-item-hover-color);
            }

            &.router-link-exact-active,
            &:active {
                background: var(--menu-item-active-background);
                color: var(--menu-item-active-color);
            }
        }
    }
}

@media(min-width: 992px) {
    // Bigger than tablet
    .website-menu {
        flex-direction: column;

        .navbar-collapse.show {
            position: fixed;
            width: 100%;
            height: calc(100vh - var(--menu-height));
            background-color: inherit;
        }
    }
}

@media(max-width: 992px) {
    // Tablet and Mobile
    .website-menu {
        .navbar-collapse.show {
            position: fixed;
            top: var(--menu-height);
            left: 0;
            width: 100%;
            height: calc(100vh - var(--menu-height));
            max-height: calc(100vh - var(--menu-height));
            background-color: inherit;
            padding: 10px;
            pointer-events: all;
            overflow-x: hidden;
            overflow-y: auto;
            z-index: 1000;
        }

        .mobile-toggle {
            color: var(--menu-item-color);
            font-weight: var(--menu-item-font-weight);
        }

        .navbar-brand {
            display: inline-block;

            img {
                height: 45px;
                max-width: 200px;
                margin: 0;
            }
        }

        .nav-item {
            &:after {
                content: "";
                display: block;
                width: 100%;
                height: 0;
                margin: 3px 0;
                border-bottom: 1px solid var(--menu-item-color);
            }

            .nav-link {
                padding: 10px 10px;
            }
        }
    }

    .container-pre-header {
        padding: 0;
    }
}
</style>
