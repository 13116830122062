<template>
    <website-page class="dynamic-map-page" container-class="h-100" page-class="h-100" no-footer>
        <map-container v-if="map && floorSelected" :map="map" :floor="floorSelected" show-controls :zoom.sync="zoom" :show-scale-legend="layers.legend">

            <layer-map-item-links v-if="!navPath && showMapDebug" :floor-links="floorLinks"/>
            <layer-map-nav-path v-if="navPath" :nav-path="navPath" :instruction="currentInstruction"/>
            <layer-map-items @item-selected="selectItem" :item-selected="itemSelected" :floor="floorSelected" :floor-items="floorItems" :nav-path="navPath" :layers="layers" :zoom="zoom"/>
            <map-current-location v-if="!currentInstruction" :item="currentLocation" :you-are-here="isKioskLayout"/>
            <map-current-location v-else :item="currentInstruction.currentItem"/>

            <template #controls>
                <map-floor-selector :map="map" :floor-selected="floorSelected" @selected="selectFloor"/>
            </template>
        </map-container>

        <map-instructions v-if="navPath" :nav-path="navPath" @close="selectItem(null)" @set-instruction="currentInstruction = $event"/>
    </website-page>
</template>

<script>
    import LayerMapItemLinks from "@/components/maps/layers/layer-map-items-links.vue";
    import LayerMapItems from "@/components/maps/layers/layer-map-items.vue";
    import MapContainer from "@/components/maps/map-container.vue";
    import MapFloorSelector from "@/components/maps/map-floor-selector.vue";
    import Network from "@/../vue-components/helpers/Network.js";
    import MapPlan from "@/helpers/maps/MapPlan.js";
    import MapMixin from "@/helpers/maps/map-mixin.js";
    import MapStore from "@/helpers/maps/map-store.js";
    import store from "@/store.js";
    import LayerMapNavPath from "../components/maps/layers/layer-map-nav-path.vue";
    import MapCurrentLocation from "../components/maps/layers/map-items/map-current-location.vue";
    import MapInstructions from "../components/maps/map-instructions.vue";
    import WayfinderHelper from "../helpers/maps/WayfinderHelper.js";

    if (!store.hasModule(`mapEditor`)) {
        // for hot reloading only
        store.registerModule(`mapEditor`, MapStore);
    }

    export default {
        name: `dynamic-map-page`,
        mixins: [MapMixin],
        components: {MapInstructions, LayerMapNavPath, MapCurrentLocation, MapFloorSelector, LayerMapItems, LayerMapItemLinks, MapContainer},
        props: {
            currentPage: { type: Object, required: true }
        },
        data() {
            return {
                currentLocation: null,
                startPoint: null,
                endPoint: null,
                itemSelected: null,
                currentInstruction: null,
                floorSelected: null,
                wayfinder: null,
                navPath: null,
                map: null,
                zoom: 1,
                layers: {
                    network: true,
                    security: true,
                    display: false,
                    indoor: true,
                    location: true,
                    navigation: true,
                    emergency: true,
                    cabling: true,
                    services: true,
                    zone: true,
                    goods: true,
                    labels: false,
                    legend: false,
                    guides: false
                }
            }
        },
        computed: {
            floorItems() {
                if (!this.map.items || !this.floorSelected) {
                    return null;
                }
                let items = this.map.items.filter(item => item.floorId === this.floorSelected.id);
                items.sort((a, b) => {
                    return a.zIndex - b.zIndex;
                })
                return items;
            },
            floorLinks() {
                if (!this.map.items || !this.floorSelected) {
                    return null;
                }
                return this.map.links.filter(link => link.floorId === this.floorSelected.id);
            },
        },
        activated() {
            this.getMap();
        },
        mounted() {
            this.getMap();
        },
        methods: {
            getMap() {
                Network.get(`/api/maps/plan/${this.currentPage.mapId}`)
                    .then(resp => {
                        this.map = new MapPlan(resp.data);

                        const mainFloor = this.map.getMainFloor();

                        this.$store.commit(`mapEditor/setMap`, this.map);
                        this.selectFloor(mainFloor);

                        // debug
                        if (this.isKioskLayout) {
                            this.currentLocation = this.map.getItemById(`y0qalu`);
                            if (this.$route.query.start) {
                                this.selectItem(this.map.getItemById(this.$route.query.start));
                            }
                        } else {
                            if (this.$route.query.start) {
                                this.startPoint = this.map.getItemById(this.$route.query.start);
                            }
                        }

                        if (this.$route.query.end) {
                            this.endPoint = this.map.getItemById(this.$route.query.end);
                            this.selectItem(this.endPoint);
                        }
                    })
            },
            selectFloor(floor) {
                this.$store.commit(`mapEditor/setFloor`, floor);
                this.floorSelected = floor;
                this.wayfinder = new WayfinderHelper(this.map, this.floorSelected);
            },
            selectItem(item) {
                if (!this.isKioskLayout && !this.currentLocation) {
                    this.currentLocation = item;
                } else {
                    this.itemSelected = item;
                }

                if (this.itemSelected) {
                    if (this.currentLocation) {
                        this.navPath = this.wayfinder.findClosestPath(this.startPoint || this.currentLocation, item);
                    } else {
                        this.currentLocation = item;
                    }
                } else if (!item) {
                    this.navPath = null;
                    this.currentInstruction = null;
                    if (!this.isKioskLayout) {
                        this.currentLocation = null;
                    }
                }
            }
        },
        watch: {
            zoom(value) {
                this.$store.commit(`mapEditor/setZoom`, value);
            }
        }
    }
</script>

<style lang="scss" scoped>
.map-container {
    flex: 1;
    height: calc(100vh - var(--menu-height));
}
</style>
