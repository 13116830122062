import MapBaseClass from "./MapBaseClass.js";
import Vector from "./Vector.js";

export default class MapLink extends MapBaseClass {
    get ignoredFields() {
        return [`map`, `masterItem`, `slaveItem`];
    }

    constructor(data, map) {
        super(data);
        this.map = map;

        this.floorId = null;
        this.name = null;
        this.type = `navigation`;
        this.color = `inherit`;
        this.distance = 0;
        this.angle = 0;
        this.dashed = true;
        this.visible = true;

        // from slave to master
        this.direction = 1;

        this.master = {
            id: null,
            plug: ``,
            plugType: ``
        };
        this.masterItem = null;

        this.slave = {
            id: null,
            plug: ``,
            plugType: ``
        };
        this.slaveItem = null;

        if (data && data.master.id) {
            this.loadSaveCompletely(data);
            this.masterItem = map.getItemById(data.master.id);
            this.slaveItem = map.getItemById(data.slave.id);
            this.linkItems(this.masterItem, this.slaveItem);
        }
    }

    applyCompatibility(data) {
        if (data.floor_id) {
            this.floorId = data.floor_id;
            delete this.floor_id;
        }
    }

    linkItems(itemA, itemB) {
        this.floorId = itemA.floorId;
        this.setMasterItem(itemA);
        this.setSlaveItem(itemB);
        this.updateCoordinates();
    }

    getMiddlePoint() {
        return {
            x: (this.masterItem.x + this.slaveItem.x) / 2,
            y: (this.masterItem.y + this.slaveItem.y) / 2
        }
    }

    getVector() {
        let vector;
        if (this.direction === 1) {
            vector = new Vector(this.masterItem.x - this.slaveItem.x, this.slaveItem.y - this.masterItem.y);
        } else {
            vector = new Vector(this.slaveItem.x - this.masterItem.x, this.masterItem.y - this.slaveItem.y);
        }
        vector.invertY();
        return vector;
    }

    updateCoordinates() {
        const vector = this.getVector();
        this.distance = vector.getLength();
        this.setStartPoint(this.slaveItem, this.masterItem);
    }

    setStartPoint(startItem) {
        if (startItem === this.slaveItem) {
            this.direction = 1;
        } else {
            this.direction = -1;
        }
        const vector = this.getVector();
        this.angle =  vector.getAngle();
    }

    isLinkBetween(itemA, itemB) {
        return (this.masterItem === itemA || this.masterItem === itemB) &&
            (this.slaveItem === itemA || this.slaveItem === itemB);
    }

    getOtherItem(item) {
        if (this.masterItem === item) {
            return this.slaveItem;
        } else  if (this.slaveItem === item) {
            return this.masterItem;
        }
    }

    setMasterItem(item) {
        if (this.masterItem) {
            this.masterItem.removeLink(this);
        }

        this.masterItem = item;
        this.master.id = item.id;
        this.updateName();

        if (!item.addLink(this)) {
            return false;
        }
    }

    setSlaveItem(item) {
        if (this.slaveItem) {
            this.slaveItem.removeLink(this);
        }

        this.slaveItem = item;
        this.slave.id = item.id;
        this.updateName();

        if (!item.addLink(this)) {
            return false;
        }
    }

    updateName() {
        if (this.masterItem && this.slaveItem) {
            this.name = `Link ${this.masterItem.name} - ${this.slaveItem.name}`;
        }
    }

    beforeDelete() {
        this.masterItem.removeLink(this);
        this.slaveItem.removeLink(this);
    }
}
