<template>
    <g v-if="navPath" class="layer layer-map-nav-path">
        <g v-for="(link, index) in navPath.links" :key="'link-'+index">
            <line class="map-nav-path"
                  :class="{ 'active': isActive(link) }" :stroke-width="scaleUI(12)"
                  :x1="pxToMeters(link.masterItem.x)" :y1="pxToMeters(link.masterItem.y)"
                  :x2="pxToMeters(link.slaveItem.x)" :y2="pxToMeters(link.slaveItem.y)" :style="makeStyle(link)"/>

            <text v-if="showMapDebug" :x="pxToMeters(link.getMiddlePoint().x)" :y="pxToMeters(link.getMiddlePoint().y)" font-weight="bold" :font-size="scaleUI(16)">
                {{link.angle}}° {{link.distance|round}}m
            </text>
        </g>
    </g>
</template>

<script>
import MapMixin from "@/helpers/maps/map-mixin.js";
export default {
    name: `layer-map-nav-path`,
    mixins: [MapMixin],
    props: {
        linkSelected: { type: Object },
        instruction: { type: Object },
        navPath: { type: Object }
    },
    methods: {
        isActive(link) {
            if (this.instruction && this.instruction.links.includes(link)) {
                return true;
            } else {
                return false;
            }
        },
        makeStyle(link) {
            return {
                strokeWidth: this.scaleUI(link.visible ? 12 : 3),
                strokeDasharray: link.dashed ? `1, ${link.visible ? 0 : 2}` : `none`
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.layer-map-nav-path {
    z-index: 0;

    .map-nav-path {
        stroke: var(--map-primary-color);
        stroke-linecap: round;

        &.active {
            stroke: purple;
        }
    }
}
</style>
