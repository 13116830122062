<template>
    <div class="map-floor-selector" v-if="map && map.floors && map.floors.length > 1">
        <div class="layer" v-for="floor in map.floors"
             :class="{ 'selected': floor && floor.id === floorSelected.id, 'floor-basement': floor.floor < 0 }"
             :style="{ 'z-index': floor.floor }" title="Select floor">
            <div class="title"><b class="f-500 f-11">{{ floor.floor }}</b> {{ floor.name }}</div>
            <button class="btn-layer" @click="selectFloor(floor)"></button>
        </div>
    </div>
</template>

<script>
export default {
    name: `map-floor-selector`,
    props: {
        map: {type: Object},
        floorSelected: {type: Object}
    },
    methods: {
        selectFloor(floor) {
            this.$emit(`selected`, floor);
        }
    }
}
</script>

<style lang="scss" scoped>
.map-floor-selector {
    position: absolute;
    z-index: 1000;
    bottom: 200px;
    right: 10px;
    padding: 5px;
    text-align: right;

    .layer {
        height: 30px;
        position: relative;

        .title {
            display: inline-block;
            opacity: 0;
            margin-right: 10px;
            text-align: right;
            font-weight: 600;
            transition: opacity 250ms, margin-right 250ms;
            pointer-events: none;
            margin-top: -7px;
            vertical-align: top;
            color: #fff;
            text-shadow: 1px 1px 4px black;
        }

        .btn-layer {
            -webkit-appearance: none;
            display: inline-block;
            width: 64px;
            height: 42px;
            color: white;
            border: none;
            background: url(https://eyeinlivestorage.blob.core.windows.net/public/icons/ui/layer-inactive.png) no-repeat center;
            background-size: contain;
            vertical-align: middle;
            margin-left: 10px;
            transform: scale(1);
            transition: transform 150ms;
            cursor: pointer;
            margin-top: -15px;

            &:hover {
                transform: scale(1.07);
            }

            &:active {
                transform: scale(0.95);
                background-image: url(https://eyeinlivestorage.blob.core.windows.net/public/icons/ui/layer-active.png);
            }
        }

        &.floor-basement:not(.selected) {
            .btn-layer {
                background-image: url(https://eyeinlivestorage.blob.core.windows.net/public/icons/ui/layer-basement.png);
            }
        }

        &.selected {
            pointer-events: none;

            .btn-layer {
                transform: scale(1.05);
                background-image: url(https://eyeinlivestorage.blob.core.windows.net/public/icons/ui/layer-active.png);
            }
        }
    }

    &:hover .layer .title {
        opacity: 1;
        margin-right: 0;
    }
}
</style>
