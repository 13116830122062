<template>
    <component :is="componentType" class="map-instructions" v-if="navPath" @close="close"
               :title="`${navPath.end.name}`"
               :min-width="450" :x="5" :y="105" lock-in-parent
               :top-margin="5" no-body expanded>
        <template #header v-if="isMobileOrTabletLayout">
            <h3 class="card-title">{{navPath.end.name}}</h3>
            <a @click.prevent="close" class="btn close"><i class="fas fa-close"></i></a>
        </template>

        <template v-if="tab === 'list'">
            <b-card class="mb-3" v-if="isKioskLayout">
                <h4 v-tr>Send directions to your phone|Envoyer le trajet sur votre téléphone</h4>

                <e-qr-code :data="qrCodeUrl" :size="150"/>

<!--                <b-input readonly :value="qrCodeUrl" style="font-size: 10pt;"/>-->
            </b-card>

            <b-card no-body>
                <b-list-group class="instruction-list">
                    <b-list-group-item v-for="(instruction, index) in navPath.instructions" @click="selectStep(index)" :key="'instr-' + index">
                        <span class="instruction-badge">
                            <span v-if="index === 0">
                                <i class="fas fa-street-view"/>
                            </span>
                            <span v-else-if="instruction.direction === 'arrived'">
                                <i class="fas fa-flag"/>
                            </span>
                            <span v-else-if="instruction.distance > 0">{{instruction.distance}}m</span>
                        </span>
                        <div class="instruction-text">{{instruction.description}}</div>
                    </b-list-group-item>
                </b-list-group>
            </b-card>
        </template>

        <template v-else>
            <div class="instruction-step text-center py-2">
                <pano360-image v-if="currentInstruction.pano_360_path" :src="currentInstruction.pano_360_path" class="instruction-pano mb-3 mx-auto" rounded
                               :direction="currentInstruction.direction" :from-angle="currentInstruction.fromAngle" :to-angle="currentInstruction.toAngle"/>
                <b-img fluid v-else :blank="!currentInstruction.image_path" blank-color="#ccc" :src="currentInstruction.image_path" class="instruction-photo mb-2" rounded/>

                <b-card>
                    <template #header>
                        <div class="step">
                            <span class="mr-1" v-tr>Step:|Étape</span> {{(index + 1)}} / {{navPath.instructions.length}}
                        </div>

                        <div class="distance">
                            <span v-if="showMapDebug">{{currentInstruction.deltaAngle}}°</span>

                            <template v-if="currentInstruction.distance > 0">
                                <b-badge variant="primary" class="distance text-white">
                                    <span>{{'In |Dans '|tr}}</span> {{currentInstruction.distance}}m
                                </b-badge>
                            </template>
                            <template v-else>
                                <span class="f-700 f-14 py-2">{{'Now|Maintenant'|tr}}</span>
                            </template>
                        </div>
                    </template>

                    <div class="instruction-text">{{currentInstruction.description}}</div>
                </b-card>
            </div>
        </template>

        <template #footer>
            <div class="w-100 text-auto">
                <template v-if="tab === 'list'">
                    <b-btn variant="primary" block @click="start">
                        <span v-tr>Begin|Commencer</span>
                    </b-btn>
                </template>
                <template v-else>
                    <b-btn variant="light" class="mr-2" @click="tab = `list`">
                        <i class="fas fa-list"/>
                    </b-btn>

                    <b-btn variant="light" @click="setIndex(index - 1)">
                        <i class="fas fa-backward" :class="fontAwesomePrefix"/>
                    </b-btn>
                    <b-btn variant="light" class="ml-2" @click="setIndex(index + 1)">
                        <i class="fas fa-forward" :class="fontAwesomePrefix"/>
                    </b-btn>

                    <b-btn variant="light" class="ml-2" @click="toggleTextToSpeech" :disabled="isKioskLayout">
                        <i class="fas" :class="muted ? 'fa-volume-xmark' : 'fa-volume-high' "/>
                    </b-btn>
                </template>
            </div>
        </template>
    </component>
</template>

<script>
import MapMixin from "@/helpers/maps/map-mixin.js";
// import EBtnRadio from "../../../vue-components/components/e-btn-radio.vue";
import EPanel from "../../../vue-components/components/e-panel.vue";
import EQrCode from "../../../vue-components/components/e-qr-code.vue";
import Pano360Image from "../pano-360-image.vue";
import EMobileCard from "../../../vue-components/components/e-mobile-card.vue";

export default {
    name: `map-instructions`,
    components: {
        Pano360Image,
        EMobileCard,
        EQrCode,
        /*EBtnRadio, */EPanel},
    mixins: [MapMixin],
    props: {
        navPath: { type: Object, required: true }
    },
    computed: {
        componentType() {
            return this.isMobileOrTabletLayout ? `e-mobile-card` : `e-panel`;
        },
        qrCodeUrl() {
            return `${window.location.protocol}//${window.location.host}${window.location.pathname}?start=${this.navPath.start.id}&end=${this.navPath.end.id}`;
        }
    },
    data() {
        return {
            tab: `list`,
            index: 0,
            currentInstruction: null,
            muted: !this.isKioskLayout,
            speechSynthesis: null
        }
    },
    activated() {
        this.setIndex(0);
    },
    mounted() {
        this.setIndex(0);
        this.speechSynthesis = new SpeechSynthesisUtterance();
        this.speechSynthesis.lang = this.getLanguage();
        this.speechSynthesis.voice = speechSynthesis.getVoices().find(voice => voice.lang.startsWith(this.getLanguage()));
    },
    methods: {
        start() {
            this.setIndex(0);
            this.tab = `steps`;
            this.playTextToSpeech();
        },
        selectStep(step) {
            this.setIndex(step);
            this.tab = `steps`;
            this.playTextToSpeech();
        },
        setIndex(index, pathChanged = false) {
            const playSound = index !== this.index || pathChanged;
            this.index = Math.max(Math.min(index, this.navPath.instructions.length - 1), 0);
            this.currentInstruction = this.navPath.instructions[this.index];
            if (playSound) {
                this.playTextToSpeech();
            }
            if (this.tab === `steps`) {
                this.$emit(`set-instruction`, this.currentInstruction);
            } else {
                this.$emit(`set-instruction`, null);
            }
        },
        toggleTextToSpeech() {
            this.muted = !this.muted;
            this.playTextToSpeech();
        },
        playTextToSpeech() {
            if (this.muted || this.tab === `list`) {
                return;
            }
            this.speechSynthesis.text = this.currentInstruction.getTextToSpeech();

            if (this.index === 0) {
                this.speechSynthesis.text = this.tr(`Let's go to|Allons à`) + ` ${this.navPath.end.name}. ${this.speechSynthesis.text}`;
            }
            window.speechSynthesis.cancel();
            window.speechSynthesis.speak(this.speechSynthesis);
        },
        close() {
            this.$emit(`close`);
        }
    },
    watch: {
        navPath() {
            this.setIndex(0, true);
        }
    }
}
</script>

<style lang="scss" scoped>
.map-instructions {
    --instruction-image-height: 315px;
    --instruction-image-width: 420px;

    ::v-deep .panel-body {
        max-height: 70vh;
        overflow: auto;
    }

    .list-group {
        border-radius: 10px;
        background: var(--lightest-color);
        text-align: left;
        font-weight: 500;

        .list-group-item {
            padding: 0;
            clear: left;
            display: flex;
            align-items: center;

            .instruction-badge {
                display: inline-block;
                border-radius: 50%;
                min-width: 42px;
                max-width: 42px;
                min-height: 42px;
                max-height: 42px;
                float: left;
                text-align: center;
                background: var(--light-color);
                line-height: 42px;
                vertical-align: middle;
                margin: 5px 10px;
            }

            .instruction-text {
                display: flex;
                align-items: center;
                padding: 5px 10px;
            }

            & + .list-group-item {
                border-top: 1px solid var(--border-color);
            }
        }
    }

    .instruction-photo {
        width: var(--instruction-image-width);
        height: var(--instruction-image-height);
        object-fit: cover;
    }

    .instruction-text {
        vertical-align: middle;
    }

    .instruction-step {
        .card-header {
            display: flex;
        }

        .distance {
            margin-left: auto;
            font-size: 1rem;
        }

        .instruction-text {
            font-weight: 500;
            min-height: 100px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    &.mobile-card {
        margin: 0;
        width: 100% !important;
        //left: 0 !important;
        //bottom: 0;
        //top: 5px;
        //padding-bottom: env(safe-area-inset-bottom);
        //transform: none !important;
        display: flex;
        flex-direction: column;
        z-index: 99999;

        --instruction-image-height: 250px;
        --instruction-image-width: 330px;

        ::v-deep .mobile-card-body {
            flex: 1;
            max-height: 100%;
        }

        ::v-deep .card-header {
            border-bottom: 1px solid var(--border-color);
        }

        .instruction-step {
            ::v-deep .card {
                ::v-deep .card-body {
                    padding: 5px;
                }
                .instruction-text {
                    font-weight: 500;
                    min-height: 80px;
                }
            }
        }

        .card {
            border-radius: 0;
        }
    }
}
</style>
