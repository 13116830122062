<template>
    <g v-if="floorLinks" class="layer layer-map-item-links">
        <g v-for="(link, index) in floorLinks" :key="'link-'+index">
            <line @click="$emit('link-selected', link)" @dblclick="$emit('link-split', $event, link)" class="map-item-link"
                  :class="{ 'selected': link === linkSelected }"
                  :x1="pxToMeters(link.masterItem.x)" :y1="pxToMeters(link.masterItem.y)"
                  :x2="pxToMeters(link.slaveItem.x)" :y2="pxToMeters(link.slaveItem.y)" :style="makeStyle(link)"/>

            <text v-if="showMapDebug" :x="pxToMeters(link.getMiddlePoint().x)" :y="pxToMeters(link.getMiddlePoint().y)" font-weight="bold" :font-size="scaleUI(16)">
                ({{link.index}}) {{link.angle}}° - {{link.distance|round}}m
            </text>
        </g>
    </g>
</template>

<script>
import MapMixin from "@/helpers/maps/map-mixin.js";
export default {
    name: `layer-map-item-links`,
    mixins: [MapMixin],
    props: {
        linkSelected: { type: Object },
        floorLinks: { type: Array }
    },
    methods: {
        makeStyle(link) {
            return {
                strokeWidth: this.scaleUI(link.visible ? 8 : 3),
                strokeDasharray: link.dashed ? `2, ${link.visible ? 0 : 2}` : `none`
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.layer-map-item-links {
    z-index: 0;

    .map-item-link {
        stroke: rgba(black, 0.25);
        stroke-linecap: round;
    }
}
</style>
