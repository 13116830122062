export default {
    namespaced: true,
    state: () => ({
        map: null,
        floor: null,
        debug: false,
        zoom: 1,
        width: 1920,
        height: 1080
    }),
    getters: {},
    actions: {},
    mutations: {
        setMap(state, map) {
            state.map = map;
        },
        setZoom(state, zoom) {
            state.zoom = zoom;
        },
        setFloor(state, floor) {
            state.floor = floor;
        }
    }
}
