export default class MapMathHelper {
    static normalizeAngle(angle, orientTop = false) {
        // we want our reference angle to be up |
        if (orientTop) {
            angle -= 90;
        }

        if (angle > 180) {
            return angle - 360;
        }
        if (angle < -180) {
            return angle + 360;
        }
        return angle;
    }

    static getAngleDirection(angle) {
        angle = MapMathHelper.normalizeAngle(angle);
        if (angle < -135) {
            return `backward-left`;
        } else if (angle < -45) {
            return `left`;
        } else if (angle < 45) {
            return `forward`
        } else if (angle < 135) {
            return `right`;
        } else {
            return `backward-right`;
        }
    }
}
