<template>
    <b-navbar :fixed="position" class="kiosk-navigation" :type="website.menu.design.theme" :style="website.menu.design.style">
        <b-navbar-nav>
            <b-nav-item @click="back()">
                <i class="fal fa-arrow-left fa-2x"></i>
                <div class="name" v-tr>Back|Retour</div>
            </b-nav-item>
        </b-navbar-nav>
        <b-navbar-nav class="mx-auto">
            <b-nav-item v-for="(link, index) in links" :key="'link-' + index" :to="link.to">
                <i v-if="link.glyph" class=" fa-2x" :class="[fontAwesomePrefix, link.glyph]"/>
                <div class="name">{{link.name|tr}}</div>
            </b-nav-item>
        </b-navbar-nav>
        <b-navbar-nav>
            <b-nav-item @click="changeLanguage">
                <i class="fal fa-flag fa-2x"></i>
                <div class="name" v-tr>FR|EN</div>
            </b-nav-item>
        </b-navbar-nav>
    </b-navbar>
</template>

<script>
import {changeLanguage} from "../../vue-components/helpers/i18n";

export default {
    name: `kiosk-navigation`,
    props: {
        position: {type: String, default: `top`}
    },
    data() {
        return {
            searchVisible: false,
            search: ``,
            loginModal: false
        }
    },
    computed: {
      links() {
          return this.website.menu.links.filter(section => {
              if (this.isMobileLayout) {
                  return (section.mobile !== false);
              } else if (this.isTabletLayout) {
                  return (section.tablet !== false);
              } else if (this.isLargeLayout) {
                  return (section.desktop !== false);
              }
              return true;
          });
      }
    },
    methods: {
        back() {
            history.back();
        },
        next() {
            history.next();
        },
        changeLanguage() {
            changeLanguage();
            this.$router.go();
        }
    }
}
</script>

<style lang="scss" scoped>
.kiosk-navigation {
    height: var(--menu-height);
    background-color: var(--navigation-bar-color);
}
.nav-item {
    display: flex;

    .nav-link {
        flex: 1;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-width: 100px;
        height: 100px;

        color: var(--menu-item-color);
        background: var(--menu-item-background);
        font-weight: var(--menu-item-font-weight);

        &:hover {
            background: var(--menu-item-hover-background);
            color: var(--menu-item-hover-color);
        }

        &.router-link-exact-active,
        &:active {
            background: var(--menu-item-active-background);
            color: var(--menu-item-active-color);
        }

        i {
            margin-bottom: 10px;
        }

        .name {
            font-size: 11pt;
            text-transform: uppercase;
            letter-spacing: 1px;
        }
    }

    //&:first-child,
    & + .nav-item {
        border-left: 1px solid rgba(white, 0.1);
    }

    //&:last-child {
    //    border-right: 1px solid rgba(white, 0.15);
    //}
}
</style>
