<template>
    <div class="pano-360-image">
        <b-img class="pano-image" :blank="!imageSrc" blank-color="#ccc" :rounded="rounded" fluid :src="imageSrc" :style="style"/>

        <b-img class="direction-arrow" :src="require(`@/assets/icons/wayfinding/direction-${currentDirection}.png`)"/>

        <div class="debug" v-if="showMapDebug">
            {{fromAngle}}° : {{toAngle}}° - {{angle}}° = {{currentAngle}}°
        </div>

        <div class="controls">
            <b-btn variant="transparent" class="rotate-left" @click="rotateLeft">
                <i class="fas fa-chevron-left"/>
            </b-btn>
            <b-btn variant="transparent" class="rotate-right" @click="rotateRight">
                <i class="fas fa-chevron-right"/>
            </b-btn>
        </div>
    </div>
</template>

<script>
import MapMixin from "../helpers/maps/map-mixin.js";
import MapMathHelper from "../helpers/maps/MapMathHelper.js";

export default {
    name: `pano-360-image`,
    mixins: [MapMixin],
    props: {
        src: { type: String, required: true },
        rounded: { type: Boolean },
        direction: { type: String, default: `forward` },
        fromAngle: { type: [Number, String] },
        toAngle: { type: [Number, String] }
    },
    data() {
        return {
            loading: false,
            angle: 0,
            imgOrientation: `top`,
            image: null,
            imageSrc: null
        }
    },
    mounted() {
        this.angle = this.fromAngle;
        this.updateImage();
    },
    computed: {
        currentAngle() {
            return MapMathHelper.normalizeAngle(this.toAngle - this.angle);
        },
        currentDirection() {
            return MapMathHelper.getAngleDirection(this.currentAngle);
        },
        style() {
            let left = `0`;
            switch (MapMathHelper.getAngleDirection(this.angle)) {
                case `backward-right`:
                case `backward-left`:
                    left = `66.66%`
                    break;
                case `left`:
                    left = `100%`
                    break;
                case `right`:
                    left = `33.33%`
                    break;
            }
            return {
                "objectPosition": `${left} 0`
            }
        }
    },
    methods: {
        rotateLeft() {
           this.angle -= 90;
            if (this.angle < -180) {
                this.angle = this.angle + 360;
            }
        },
        rotateRight() {
           this.angle += 90;
            if (this.angle > 180) {
                this.angle = this.angle - 360;
            }
        },
        updateImage() {
            // otherwise when setting directly the image, it rotates the image of the last point instead of the new one
            this.imageSrc = null;
            this.image = new Image();
            this.image.onload = () => {
                this.imageSrc = this.image.src;
            };
            this.image.src = this.src;
        }
    },
    watch: {
        src() {
            this.updateImage();
        },
        fromAngle(value) {
            this.angle = value;
        }
    }
}
</script>

<style lang="scss" scoped>
.pano-360-image {
    position: relative;
    width: var(--instruction-image-width);
    height: var(--instruction-image-height);

    .pano-image {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }

    .direction-arrow {
        position: absolute;
        left: 20%;
        width: 60%;
        bottom: 0;
        z-index: 1;
    }

    .debug {
        position: absolute;
        left: 0;
        top: 0;
        background: white;
        padding: 3px 6px;
        border-bottom-right-radius: 8px;
    }

    .controls {
        .btn {
            position: absolute;
            top: 0;
            width: 80px;
            height: 100%;
            border: none;

            .fas {
                font-size: 24px;
                color: white;
                text-shadow: 0 5px 5px rgba(black, 0.2);
            }

            &:hover {
                background-color: rgba(255, 255, 255, 0.25);
            }

            &.rotate-left {
                left: 0;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
            &.rotate-right {
                right: 0;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }
    }
}
</style>
