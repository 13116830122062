<template>
    <div ref="card" class="e-mobile-card mobile-card" :style="draggingStyle">
        <div v-if="isMobileOrTabletLayout" class="grip"></div>
        <div v-if="!noHeader" class="card-header mobile-card-header">
            <slot name="header">
                <h4 class="card-title">{{title}}</h4>
            </slot>
        </div>
        <div class="mobile-card-body" :class="{ 'card-body': !noBody }" ref="cardBody">
            <slot></slot>
        </div>
        <div v-if="!noFooter" class="card-footer mobile-card-footer">
            <slot name="footer"></slot>
        </div>
    </div>
</template>

<script>
    import {getSafeArea} from "../helpers/utils.js";

    export default {
        name: `e-mobile-card`,
        props: {
            title: {type: String},
            expanded: {type: Boolean},
            topMargin: {type: Number, default: 100},
            noBody: {type: Boolean},
            noHeader: {type: Boolean},
            noFooter: {type: Boolean}
        },
        data() {
            return {
                draggingCard: false,
                cardHeightTransition: 0,
                draggingStart: null,
                lastTouch: null,
                cardHeight: 0,
                velocity: 0,
                decelerateInterval: null,
                heightAvailable: 0
            }
        },
        computed: {
            draggingStyle() {
                if (!this.isMobileOrTabletLayout) {
                    return {};
                } else {
                    return {
                        height: `${this.cardHeight + this.cardHeightTransition}px`,
                        transition: this.draggingCard ? `height 0ms` : `height 300ms ease`
                    };
                }
            },
            cardOverExtended() {
                return this.cardHeight + this.cardHeightTransition >= this.heightAvailable;
            },
            cardMaxExtended() {
                return this.cardHeight + this.cardHeightTransition > this.maxCardHeight();
            }
        },
        async created() {
            await this.$nextTick();

            const safeArea = getSafeArea();
            this.heightAvailable = window.innerHeight - (this.topMargin + safeArea.top);

            window.addEventListener(`resize`, _ => {
                const safeArea = getSafeArea();
                this.heightAvailable = window.innerHeight - (this.topMargin + safeArea.top);

                if (this.cardHeight > 170) {
                    this.cardHeight = this.heightAvailable;
                    this.cardHeightTransition = 0;
                    this.draggingCard = true;
                }
            });

            if (window.screen.orientation) {
                window.screen.orientation.addEventListener(`change`, () => {
                    this.$router.go();
                });
            } else {
                window.addEventListener(`orientationchange`, () => {
                    this.$router.go();
                });
            }

            this.$refs.card.addEventListener(`touchstart`, event => {
                clearInterval(this.decelerateInterval);
                this.draggingCard = true;
                this.cardHeightTransition = 0;
                this.draggingStart = event.touches[0];
                this.draggingStart.timestamp = event.timeStamp;
            });
            this.$refs.card.addEventListener(`touchmove`, event => {
                const touch = event.touches[0];
                touch.timestamp = event.timeStamp;

                this.cardHeightTransition = this.draggingStart.clientY - touch.clientY;
                let lastTouch = this.lastTouch || this.draggingStart;
                this.velocity = 1000 * (touch.clientY - lastTouch.clientY) / (touch.timestamp - lastTouch.timestamp);

                event.preventDefault();
                this.lastTouch = touch;
            });
            this.$refs.card.addEventListener(`touchend`, event => {
                this.draggingCard = false;
                this.draggingStart = null;
                let movingDown = this.cardHeightTransition < 0;
                let movingUp = this.cardHeightTransition > 0;

                if (movingDown) {
                    if (!this.cardOverExtended) {
                        this.hideCard();
                    } else if (this.cardMaxExtended) {
                        this.showMaxCard();
                    } else {
                        this.cardHeight += this.cardHeightTransition;
                        this.cardHeightTransition = 0;
                        this.decelerateCard();
                    }
                } else if (movingUp) {
                    if (!this.cardOverExtended) {
                        this.showCard();
                    } else if (this.cardMaxExtended) {
                        this.showMaxCard();
                    } else {
                        this.cardHeight += this.cardHeightTransition;
                        this.cardHeightTransition = 0;
                        this.decelerateCard();
                    }
                }

                this.lastTouch = null;
            });

            if (this.expanded) {
                this.showMaxCard();
            } else {
                this.hideCard();
            }
        },
        methods: {
            close() {
                this.$emit(`close`);
            },
            maxCardHeight() {
                return this.$refs.cardBody.clientHeight + this.topMargin;
            },
            hideCard() {
                this.cardHeight = 170;
                this.cardHeightTransition = 0;
                this.$emit(`hide`);
            },
            showCard() {
                this.cardHeight = this.heightAvailable;
                this.cardHeightTransition = 0;
                this.$emit(`show`);
            },
            showMaxCard() {
                this.cardHeight = Math.max(this.maxCardHeight(), this.heightAvailable);
                this.cardHeightTransition = 0;
                this.$emit(`extend`);
            },
            decelerateCard() {
                if (this.cardOverExtended) {
                    this.draggingCard = true;
                    const deceleration = 0.2;
                    const initialTime = Date.now();
                    const initialHeight = this.cardHeight;
                    this.decelerateInterval = setInterval(_ => {
                        let time = (Date.now() - initialTime) / 1000;
                        let velocity = this.velocity * Math.pow(deceleration, time);
                        let deltaPosition = this.velocity * (Math.pow(deceleration, time) - 1) / Math.log(deceleration);

                        this.cardHeight = initialHeight - deltaPosition;

                        if (this.cardMaxExtended || !this.cardOverExtended || Math.abs(velocity) < 1) {
                            clearInterval(this.decelerateInterval);
                            this.draggingCard = false;
                            if (this.cardMaxExtended)
                                this.showMaxCard();
                            else if (!this.cardOverExtended)
                                this.showCard();
                        }
                    }, 1);
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
.e-mobile-card {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding-top: 28px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    box-shadow: 0 -5px 15px rgba(0, 0, 0, 0.1);
    background-color: #f3f3f3;
    color: #000;

    .grip {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 28px;
        background-color: white;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;

        &:after {
            content: "";
            position: absolute;
            display: block;
            top: 10px;
            left: calc(50% - 40px);
            height: 6px;
            width: 80px;
            border-radius: 3px;
            background-color: #ddd;
        }
    }

    .card-header {
        display: flex;
        width: 100%;
        padding: 10px 10px 10px 20px;
        border-bottom: none;
        background: white;
        color: #333;
        align-items: center;
        user-select: none;
        //border-top-left-radius: inherit;
        //border-top-right-radius: inherit;
        cursor: move;

        .card-title {
            margin: 0;
        }

        ::v-deep h4 {
            margin-top: 0;
            margin-bottom: 0;
        }

        .close {
            position: relative;
            float: right;
            margin: 0 0 0 auto;
            padding: 0;
            border-radius: 5px;
            background: #ddd;
            font-weight: 600;
            width: 40px !important;
            height: 40px !important;
            display: flex;
            align-items: center;
            justify-content: center;
            text-shadow: none;
            color: #444;
            opacity: 1;

            & + .btn {
                margin-left: 5px;
            }

            &:hover {
                background: #bbb;
            }
        }
    }

    .mobile-card-body {
        overflow: hidden;
    }

    .card-footer {
        background: white;
        color: #333;
        display: flex;
        width: 100%;
        padding: 10px;
        justify-content: flex-end;
        border-top: none;
        align-items: center;
        //border-bottom-left-radius: inherit;
        //border-bottom-right-radius: inherit;

        ::v-deep .btn {
            padding: 10px 20px;

            &.btn-secondary {
                background: #eee;
                color: #333;
                border: 1px solid rgba(black, 0.15);
                outline: none;

                &:hover {
                    background: #ddd;
                }

                &:active {
                    background: #ccc;
                }
            }
        }
    }
}

.large-layout {
    .e-mobile-card {
        // top: 44px;
        right: 0;
        left: auto;
        height: calc(100vh - 51px) !important;
        max-height: calc(100vh - 51px);
        overflow-x: hidden;
        overflow-y: auto;
        width: 320px;
        max-width: 100% !important;
        padding-top: 0;
        border-radius: 0;
        box-shadow: none;
        border-left: 1px solid #ddd;
    }
}
</style>
