<template>
    <website-page :virtual-keyboard="isKioskLayout">
        <div class="static-header pt-5 pb-3">
            <b-container class="text-center">
                <h1 v-tr>Looking for somewhere or someone?|Vous cherchez un endroit ou quelqu'un ?</h1>

                <div class="main-search-container">
                    <i class="icon-search fal fa-search"/>
                    <b-input class="main-search" v-model="search" :placeholder="'Room # or Doctor name|Nom de médecin ou # de salle'|tr"/>
                    <i v-if="search !== ``" @click="clearSearch" class="icon-clear fal fa-close"/>
                </div>
            </b-container>
        </div>

        <b-container class="py-3">
            <template v-if="!search">
                <b-list-group class="search-list">
                    <search-list-item v-for="item in cms.items" :key="'search-' + item.id" :item="item" @click="selectItem(item)"/>
                </b-list-group>
            </template>
            <template v-else-if="search && searchResults.length === 0">
                <e-empty-placeholder title="No Search Results|Aucun résultat trouvé" description="No Store seems to match your search. Please try another search.|Aucun magasin ne semble correspondre à votre recherche. Essayez avec d'autres mots"/>
            </template>
            <template v-else-if="search && searchResults">
                <b-list-group class="search-list">
                    <search-list-item v-for="item in searchResults" :key="'search-' + item.id" :item="item" :logo="searchResults.length < 10" @click="selectItem(item)"/>
                </b-list-group>
            </template>

            <item-summary v-if="itemSelected" :item="itemSelected" @hidden="unselectItem"/>
        </b-container>

        <template v-if="isKioskLayout" #keyboard>
            <virtual-keyboard v-show="!itemSelected" ref="keyboard" v-model="search" @change="onKeyboardChange"/>
        </template>
    </website-page>
</template>

<script>
import EEmptyPlaceholder from "../../vue-components/components/e-empty-placeholder.vue";
import ItemSummary from "../components/cms/item-summary.vue";
import SearchListItem from "../components/cms/search-list-item.vue";
import VirtualKeyboard from "../components/virtual-keyboard.vue";
import latinize from "latinize"

export default {
    name: `search`,
    components: {ItemSummary, EEmptyPlaceholder, SearchListItem, VirtualKeyboard},
    data() {
        return {
            search: ``,
            itemSelected: null
        }
    },
    computed: {
        searchResults() {
            if (!this.search) {
                return null
            } else {
                let results = [];
                const search = latinize(this.search).toLowerCase();
                let searchRegexp = new RegExp(search, `gi`);
                for (let item of this.cms.items) {
                    if (searchRegexp.test(latinize(item.name.toLowerCase()))) {
                        results.push(item);
                    }
                }
                return results;
            }
        }
    },
    methods: {
        onKeyboardChange(input) {
            this.search = input;
        },
        clearSearch() {
            this.$refs.keyboard.clear();
            this.search = ``;
            if (this.$refs.search) {
                this.$refs.search.focus();
            }
        },
        selectItem(store) {
            this.itemSelected = store;
        },
        unselectItem() {
            this.itemSelected = null;
        }
    }
}
</script>

<style lang="scss" scoped>
.main-search-container {
    max-width: 600px;
    margin: 50px auto 0;
    position: relative;

    --search-height: 60px;

    .icon-search,
    .icon-clear {
        position: absolute;
        top: 0;
        width: var(--search-height);
        text-align: center;
        line-height: var(--search-height);
        font-size: 30px;
        opacity: 0.5;
    }

    .icon-search {
        left: 0;
    }
    .icon-clear {
        right: 0;
    }

    .main-search {
        padding: calc(var(--search-height) / 2) var(--search-height) !important;
        font-size: 20pt;
    }
}

.kiosk-layout {
    .main-search-container {
        --search-height: 80px;

        .main-search {
            pointer-events: none;
        }
    }
}
</style>
