import Vue from 'vue';
import App from './App.vue';
import router from './router.js';
import store from './store.js';
import "../vue-components/helpers/vue-mixin.js";
import './vue-global';

import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'

import VueSwal from 'vue-swal'
import moment from 'moment';
import VueMoment from 'vue-moment'
import WebsitePage from '/src/layout/website-page.vue'
import EImg from '../vue-components/components/e-img.vue'
import EContainer from '../vue-components/components/e-container.vue'

import '../vue-components/helpers/vue-filters.js';

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueSwal);
Vue.use(VueMoment, {
    moment
});

Vue.component(`website-page`, WebsitePage);
Vue.component(`e-img`, EImg);
Vue.component(`e-container`, EContainer);

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount(`#app`);
