import {tr} from "../../../vue-components/helpers/i18n.js";
import MapMathHelper from "./MapMathHelper.js";
import Vector from "./Vector.js";

export default class MapInstructions {
    constructor(previousItem, currentItem, nextItem) {
        this.previousItem = previousItem;
        this.currentItem = currentItem;
        this.nextItem = nextItem;
        // in case with merged multiple links
        this.links = [];

        if (!this.previousItem) {
            // TODO add initial kiosk orientation if it's the first move
            this.previousVector = new Vector(0, -1);
            this.distance = 0;
        } else {
            this.previousVector = new Vector(this.previousItem.x - this.currentItem.x, this.currentItem.y - this.previousItem.y);

            this.link = currentItem.links.find(l => l.isLinkBetween(this.currentItem, this.previousItem));
            this.links.push(this.link);
            this.distance = Math.round(this.link.distance);
        }

        if (this.nextItem) {
            this.nextVector = new Vector(this.currentItem.x - this.nextItem.x, this.nextItem.y - this.currentItem.y);
        } else {
            this.nextVector = new Vector(0, -1);
        }

        // invert y to get correct angle since coordinates are from top to bottom
        this.previousVector.invertY();
        this.nextVector.invertY();

        this.fromAngle = this.previousVector.getAngle();
        this.toAngle = this.nextVector.getAngle();
        this.deltaAngle = this.previousVector.getDiffAngle(this.nextVector);

        this.description = ``;
        if (this.currentItem.description) {
            this.description = tr(this.currentItem.description) + `. `;
        }
        this.pano_360_path = this.currentItem.pano_360_path || null;
        this.image_path = this.currentItem.image_path || null;

        if (!this.nextItem) {
            this.setArrival();
        } else {
            this.direction = MapMathHelper.getAngleDirection(this.deltaAngle);

            if (!this.previousItem) {
                // first item
                switch (this.direction) {
                    case `right`:
                        this.description += tr(`Head right|Commencez sur votre droite`);
                        break;
                    case `left`:
                        this.description += tr(`Head left|Commencez sur votre gauche`);
                        break;
                    case `forward`:
                        this.description += tr(`Head straight|Commencez tout droit`);
                        break;
                    case `backward-left`:
                    case `backward-right`:
                        this.description += tr(`Turn around|Commencez derrière vous`);
                        break;
                }
            } else {
                switch (this.direction) {
                    case `right`:
                        this.description += tr(`Turn right|Prenez sur votre droite`);
                        break;
                    case `left`:
                        this.description += tr(`Turn left|Prenez sur votre gauche`);
                        break;
                    case `forward`:
                        this.description += tr(`Continue straight|Continuez tout droit`);
                        break;
                    case `backward-left`:
                    case `backward-right`:
                        this.description += tr(`Turn around|Retournez-vous`);
                        break;
                }
            }
        }

        // console.log(`${currentItem.index} -> ${this.deltaAngle}° - ${this.direction}`);
    }

    setArrival() {
        this.direction = `arrived`;

        this.description = tr(`You arrived|Vous êtes arrivés`);
    }

    mergeWith(navInstruction) {
        this.distance += navInstruction.distance;
        this.fromAngle = navInstruction.fromAngle;
        // this.toAngle = navInstruction.toAngle;
        // this.deltaAngle = navInstruction.deltaAngle;
        if (navInstruction.link) {
            this.links = this.links.concat(navInstruction.links);
        }
    }

    getTextToSpeech() {
        let textToSpeech;
        if (this.distance === 0) {
            textToSpeech = tr(``);
        } else {
            textToSpeech = tr(`In ${this.distance}m, |Dans ${this.distance}m, `);
        }

        if (!this.nextItem) {
            textToSpeech += tr(`You arrived to ${tr(this.currentItem.name)}|Vous êtes arrivés à ${tr(this.currentItem.name)}`);
        } else {
            textToSpeech += this.description;
        }
        return textToSpeech;
    }
}
