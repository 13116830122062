<template>
    <footer class="footer" :class="['theme-' + website.footer.design.theme, 'bg-' + website.footer.design.variant]">
        <b-container>
            <b-row>
                <b-col cols="12" md="6" lg="4" class="mb-3 mb-lg-0" v-for="(section, sectionIndex) in website.footer.sections" :key="'section-' + sectionIndex">
                    <h5>{{section.name|tr}}</h5>
                    <template v-if="section.type === 'links'">
                        <div v-for="(item, itemIndex) in section.items" :key="sectionIndex + '-' + itemIndex">
                            <website-item :value="item"/>
                        </div>
                    </template>
                    <template v-else-if="section.type === 'buttons'">
                        <website-item v-for="(item, itemIndex) in section.items" :key="sectionIndex + '-' + itemIndex" :value="item"/>
                    </template>
                </b-col>
            </b-row>
            <div class="d-block mt-4 text-center">
                <p class="f-500">{{website.legal.copyright|tr}}</p>
            </div>
        </b-container>
    </footer>
</template>

<script>
    import WebsiteItem from "@/components/website-item.vue";
    import {isFrench} from "../../vue-components/helpers/i18n";

    export default {
        name: `app-footer`,
        components: {WebsiteItem},
        data() {
            return {
                isFrench: isFrench
            }
        }
    }
</script>

<style lang="scss" scoped>
    .footer {
        padding: 30px 0;

        ::v-deep .btn {
            & + .btn {
                margin-left: 8px;
            }
        }
    }
</style>
