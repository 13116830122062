<template>
    <g v-if="floor && floorItems" class="layer layer-objects">
        <template v-for="(item, index) in floorItems" class="map-item" :class="[item.category, 'type-' + item.type]">
            <g :key="'item-'+index">
                <map-item-point @selected="$emit(`item-selected`, $event)" :item="item" :active="isActive(item)" :selected="item === itemSelected" v-show="layers[item.category] || showMapDebug"
                                       :floor="floor" :zoom="zoom" :ap-stats="apStats" :show-stats="apStats !== null" :range-display="rangeDisplay"/>
            </g>
        </template>
    </g>
</template>

<script>
import MapMixin from "@/helpers/maps/map-mixin.js";
import MapItemPoint from "./map-items/map-item-point.vue";
export default {
    name: `layer-map-items`,
    mixins: [MapMixin],
    components: {MapItemPoint},
    props: {
        itemSelected: { type: Object },
        floorItems: { type: Array },
        floor: { type: Object },
        layers: { type: Object },
        navPath: { type: Object },
        zoom: { type: Number },
        rangeDisplay: { type: String, default: `none` },
        apStats: { type: Object, default: null }
    },
    data() {
        return {
            heatmap: null
        }
    },
    methods: {
        isActive(item) {
            return this.navPath && this.navPath.items.includes(item);
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
