<template>
    <website-page>
        <b-container>
            <h1 class="my-4">{{currentPage.name|tr}}</h1>

            <b-card no-body class="mx-auto mb-4" style="max-width: 600px;">
                <b-list-group class="no-borders">
                    <b-list-group-item class="interactive" v-for="item in items" :key="'category-' + item.id" @click="selectItem(item)" :img-src="item.coverImage" img-left>
<!--                        <i class="fas fa-circle color mr-2" v-if="item.color" :style="{ 'color': item.color }"/>-->
                        <i class="mr-1 fa-fw" :class="[fontAwesomePrefix, item.glyph || item.typeDef.glyph]"/>
                        <span class="name">{{item.name}}</span>
                        <span class="ml-auto secondary-label" v-if="item.getSecondaryValue()">{{item.getSecondaryValue()}}</span>
                    </b-list-group-item>
                </b-list-group>
            </b-card>
        </b-container>

        <item-summary v-if="itemSelected" :item="itemSelected" @hidden="unselectItem" show-map-link/>
    </website-page>
</template>

<script>
import ItemSummary from "../components/cms/item-summary.vue";

export default {
    name: `cms-item-list-page`,
    components: {ItemSummary},
    props: {
        currentPage: { type: Object, required: true }
    },
    data() {
        return {
            loading: false,
            items: null,
            rooms: null,
            employees: null,
            departmentSelected: null,
            itemSelected: null
        }
    },
    mounted() {
        this.loadItems();
    },
    methods: {
        loadItems() {
            this.items = this.cms.getItemsByType(this.currentPage.itemType);
        },
        selectItem(item) {
            this.itemSelected = item;
        },
        unselectItem() {
            this.itemSelected = null;
        }
    },
    watch: {
        currentPage() {
            this.loadItems();
        }
    }
}
</script>

<style lang="scss" scoped>
.list-group {
    .list-group-item {
        text-align: left;
        padding: 10px 10px;
        font-size: 15pt;
        border-bottom: 1px solid #ccc;
        display: flex;
        align-items: center;
        cursor: pointer;

        .secondary-label {
            opacity: 0.8;
            font-size: 11pt;
        }
    }
}

.kiosk-layout {
    .list-group .list-group-item {
        padding: 20px 20px;
        font-size: 16pt;

        .secondary-label {
            opacity: 0.8;
            font-weight: 500;
            font-size: 15pt;
        }
    }
}
</style>
