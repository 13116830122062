<template>
    <vue-draggable-resizable :x="x" :y="y" :draggable="draggable" :resizable="resizable" :parent="lockInParent" drag-handle=".panel-header"
                             class="e-panel animated fadeIn" :class="{ 'fadeIn': visible, 'fadeOut': !visible }" :style="computedStyle">
        <div class="panel-header">
            <slot name="header">
                <h4 class="panel-title">{{title}}</h4>
            </slot>
            <slot name="close" v-bind="{hide}">
                <a @click.prevent="hide" class="btn close"><i class="fas fa-close"></i></a>
            </slot>
        </div>
        <div class="panel-body">
            <slot></slot>
        </div>
        <div class="panel-footer" v-if="!noFooter">
            <slot name="footer"></slot>
        </div>
    </vue-draggable-resizable>
</template>

<script>
    import VueDraggableResizable from "vue-draggable-resizable"

    export default {
        name: `e-panel`,
        components: {
            VueDraggableResizable
        },
        props: {
            title: { type: String, default: `` },
            draggable: { type: Boolean, default: () => !isMobileLayout },
            resizable: { type: Boolean },
            lockInParent: { type: Boolean },
            minWidth: { type: [String, Number] },
            noFooter: { type: Boolean },
            x: { type: [String, Number], default: window.innerWidth / 2 - 400 },
            y: { type: [String, Number], default: 100 }
        },
        data() {
            return {
                visible: true
            }
        },
        computed: {
            computedStyle() {
                return {
                    'min-width': `${this.minWidth}px`
                }
            },
            left() {
                return window.innerWidth / 2 - 400;
            }
        },
        mounted() {
            if (this.isMobileLayout) {
                this.x = 0;
                this.y = 0;
            }
        },
        methods: {
            show() {
                this.visible = true;
                this.$emit(`shown`);
            },
            hide() {
                this.visible = true;
                this.$emit(`close`);
                this.$emit(`hidden`);
            }
        }
    }
</script>

<style lang="scss" scoped>
    .e-panel {
        position: fixed !important;
        top: 0;
        left: 0;
        height: auto !important;
        z-index: 4000 !important;
        margin: 5px;
        user-select: none;
        color: rgba(255, 255, 255, 0.9);
        border-radius: 5px;
        background: linear-gradient(230deg, #0c3749 0%, #1c2f3e 100%);
        box-shadow: 2px 3px 10px 0 rgba(0, 0, 0, 0.25);

        .panel-body {
            border-radius: inherit;
            padding: 10px;
        }

        .panel-header {
            display: flex;
            width: 100%;
            padding: 10px 10px 10px 20px;
            border-bottom: none;
            background: white;
            color: #333;
            align-items: center;
            user-select: none;
            border-top-left-radius: inherit;
            border-top-right-radius: inherit;
            cursor: move;

            .panel-title {
                text-transform: initial;
                font-weight: 500;
                font-size: 14pt;
                letter-spacing: 1px;
                margin: 0;
            }

            ::v-deep h4 {
                margin-top: 0;
                margin-bottom: 0;
            }

            .close {
                position: relative;
                float: right;
                margin: 0 0 0 auto;
                padding: 10px 10px 12px;
                border-radius: 5px;
                background: #ddd;
                font-weight: 600;
                width: 40px !important;
                height: 40px !important;
                display: flex;
                align-items: center;
                justify-content: center;
                text-shadow: none;
                color: #444;
                opacity: 1;

                & + .btn {
                    margin-left: 5px;
                }

                &:hover {
                    background: #bbb;
                }
            }
        }

        .panel-footer {
            background: white;
            color: #333;
            display: flex;
            width: 100%;
            padding: 10px;
            justify-content: flex-end;
            border-top: none;
            align-items: center;
            border-bottom-left-radius: inherit;
            border-bottom-right-radius: inherit;

            ::v-deep .btn {
                padding: 10px 20px;

                &.btn-secondary {
                    background: #eee;
                    color: #333;
                    border: 1px solid rgba(black, 0.15);
                    outline: none;

                    &:hover {
                        background: #ddd;
                    }

                    &:active {
                        background: #ccc;
                    }
                }
            }
        }
    }

    @media(max-width: 576px) {
        .panel {
            left: 0 !important;
            top: 0 !important;
            right: 0 !important;
            bottom: 0 !important;
            width: auto !important;
            height: auto !important;
        }
    }
</style>
