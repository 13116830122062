import CmsItem from "./CmsItem.js";
import CmsType from "./CmsType.js";

export default class CMS {
    constructor() {
        this.items = [];
        this.types = [];
    }

    initTypes(types) {
        this.types = this.types.concat(types.map(type => new CmsType(type)));
    }

    addItems(items) {
        this.items = this.items.concat(items.map(item => new CmsItem(item, this.getTypeById(item.type))));
    }

    updateDependencies() {
        for (let type of this.types) {
            let items = this.getItemsByType(type.id);
            for (let item of items) {
                for (let key in type.keys) {
                    item[key] = this.getItemById(item[type.keys[key]]);
                }
            }
        }
    }

    getTypeById(id) {
        return this.types.find(type => type.id === id);
    }

    getItemById(id) {
        return this.items.find(i => i.id === id);
    }

    getItemsByType(type) {
        return this.items.filter(i => i.type === type);
    }
}
