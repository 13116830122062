<template>
    <g v-if="item" class="map-current-location" :class="{ 'you-are-here': youAreHere }" :transform="getTransform">
        <circle @mousedown.self="$emit('selected', item)" class="circle-around" :style="style" :r="scaleUI(64)"/>
        <circle @mousedown.self="$emit('selected', item)" class="circle-centre" :style="style" :r="scaleUI(18)"/>

        <text v-if="youAreHere" :y="scaleUI(48)" :font-size="scaleUI(32)" :style="style" paint-order="stroke" dominant-baseline="hanging" text-anchor="middle">
            {{'You are here|Vous êtes ici'|tr}}
        </text>
    </g>
</template>

<script>
import MapHelper from "@/helpers/maps/MapHelper.js";
import MapMixin from "@/helpers/maps/map-mixin.js";

export default {
    name: `map-current-location`,
    mixins: [MapMixin],
    props: {
        item: { type: Object, required: true },
        youAreHere: { type: Boolean }
    },
    data() {
        return {
            MapHelper
        }
    },
    computed: {
        getTransform() {
            return `translate(${this.pxToMeters(this.item.x)} ${this.pxToMeters(this.item.y)})`;
        },
        style() {
            return {
                strokeWidth: this.scaleUI(6)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.map-current-location {
    z-index: 10;

    .circle-around,
    .circle-centre {
        stroke: white;
        stroke-width: 1px;
        fill: var(--map-secondary-color);
    }

    .circle-around {
        opacity: 0.2;
    }

    text {
        stroke: white;
        font-weight: 700;
        fill: var(--map-secondary-color);
    }

    &.you-are-here {
        .circle-around,
        .circle-centre,
        text{
            fill: var(--map-primary-color);
        }
    }
}
</style>
