<template>
    <header class="navigation-bar-container">
        <div class="navigation-bar" :class="['theme-' + theme, {'fixed': !notFixed}]">
            <div class="navigation-container" :class="{ 'container': useContainer }">
                <div class="navigation-part navigation-start">
                    <router-link v-if="!noLogo && logoSrc" :to="homeRoute" class="navigation-logo">
                        <b-img :src="logoSrc"/>
                    </router-link>

                    <slot name="start"></slot>
                </div>

                <div class="navigation-part navigation-title">
                    <slot name="title"></slot>
                </div>

                <div class="navigation-part navigation-end" v-if="$slots.end">
                    <slot name="end"></slot>
                </div>

                <slot></slot>
            </div>
        </div>
    </header>
</template>

<script>
export default {
    name: `e-navigation-bar`,
    props: {
        useContainer: { type: Boolean },
        notFixed: { type: Boolean },
        noLogo: { type: Boolean },
        logoSrc: { type: String },
        theme: { type: String, default: `light` },
        homeRoute: { type: String }
    }
}
</script>

<style lang="scss" scoped>
.navigation-bar-container {
    position: relative;
    height: var(--navigation-bar-height);
}

.navigation-bar {
    &.fixed {
        position: fixed;
        top: 0;
        left: 0;
    }
    height: var(--navigation-bar-height);
    display: flex;
    align-items: center;

    background-color: rgba(255, 255, 255, 0.9);
    backdrop-filter: saturate(180%) blur(10px);
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.15);

    width: 100%;
    padding-top: calc(0px + env(safe-area-inset-top));
    z-index: 99;

    &.theme-dark {
        background-color: rgba(0, 0, 0, 0.9);
        color: white;

        .btn-profile div {
            color: white;
        }
    }

    .navigation-container {
        display: flex;
        flex-direction: row;
        height: 100%;
        width: 100%;
        position: relative;
        justify-content: center;
    }

    .navigation-part {
        display: flex;
        flex-direction: row;
        height: 100%;
        justify-content: center;
    }

    .navigation-start {
        position: absolute;
        left: 0;
        top: 0;
    }

    .navigation-part.navigation-title {
        position: relative;
        align-items: center;
        text-align: center;
        flex-direction: column;
    }

    .navigation-end {
        position: absolute;
        right: 0;
        top: 0;
    }

    .navigation-logo {
        margin-right: 5px;
        height: 100%;

        img {
            max-width: 128px;
            height: calc(100% - 5px);
            object-fit: contain;
        }
    }

    .btn {
        margin: 5px 5px;

        &.btn-icon {
            margin-top: 0;
            margin-bottom: 0;
        }
    }
}
</style>
