<template>
    <component :is="this.componentType" :current-page="currentPage"/>
</template>

<script>
import SeoHelper from "../../vue-components/helpers/SeoHelper.js";
import CmsMapPage from "./cms-map-page.vue";
import CmsHtmlPage from "./cms-html-page.vue";
import CmsItemListPage from "./cms-item-list-page.vue";
import CmsItemDetailsPage from "./cms-item-details-page.vue";
import DefaultErrorPage from "./default-error-page.vue";

export default {
    name: `dynamic-page`,
    components: {CmsMapPage, CmsHtmlPage, CmsItemListPage, CmsItemDetailsPage, DefaultErrorPage},
    metaInfo() {
        if (this.currentPage) {
            return SeoHelper.getMeta(this.tr(this.currentPage.name), this.tr(this.currentPage.name));
        } else {
            return null;
        }
    },
    computed: {
        componentType() {
            if (!this.currentPage) {
                return `default-error-page`;
            }

            switch (this.currentPage.type) {
                case `map`:
                    return `cms-map-page`;
                case `cms-item-list`:
                    return `cms-item-list-page`;
                case `cms-item-details`:
                    return `cms-item-details-page`;
                default:
                    return `cms-html-page`;
            }
        },
        currentPage() {
            for (let page of this.website.pages) {
                if (page.alias.en === this.$route.params.alias || page.alias.fr === this.$route.params.alias) {
                    return page;
                }
            }
            return null;
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
