import Vue from 'vue';
import VueRouter from 'vue-router';
import store from "./store.js";

import home from './views/home.vue';
import error from './views/error-site-construction.vue';
import search from './views/search.vue';
import checkIn from './views/check-in.vue';
import dynamicPage from './views/cms-page.vue';
// import pageDynamic from './views/map.vue';

Vue.use(VueRouter);

function loadPage(to, from, next) {
    if (!store.getters.currentWebsite) {
        next();
    } else {
        store.dispatch(`loadPage`, to.params.alias)
            .then(() => {
                if (store.getters.currentPage) {
                    next();
                } else {
                    next(`/error`);
                }
            });
    }
}

const routes = [
    {
        path: `/`,
        name: `home`,
        component: home,
        beforeEnter: loadPage
    },
    {
        path: `/search`,
        name: `search`,
        component: search
    },
    {
        path: `/check-in`,
        name: `check-in`,
        component: checkIn
    },
    {
        path: `/error`,
        name: `error`,
        component: error
    },
    {
        path: `/:alias`,
        name: `dynamic-page`,
        component: dynamicPage,
        beforeEnter: loadPage
    },
    {
        path: `/:alias/*`,
        name: `dynamic-page`,
        component: dynamicPage,
        beforeEnter: loadPage
    },
    // {
    //     path: `/:alias`,
    //     name: `widget-map`,
    //     component: widgetMap
    // }
];

const router = new VueRouter({
    mode: `history`,
    routes: routes
});

export default router;
