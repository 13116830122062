import MapBaseClass from "./MapBaseClass.js";

export default class MapItem extends MapBaseClass {
    get ignoredFields() {
        return [`map`, `links`, `settings`];
    }

    constructor(data, map) {
        super(data);
        this.map = map;

        this.links = [];
    }

    addLink(link) {
        // const existingLink = this.links.find(l => l.id === linkedItem.id);
        this.links.push(link)
    }

    removeLink(link) {
        this.links.removeItem(link);
    }
}
