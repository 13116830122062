import {tr} from "../../../vue-components/helpers/i18n.js";

export default class CmsType {
    static get TYPES() {
        return {
            ROOM: `room`,
            SERVICE: `service`,
            DEPARTMENT: `department`,
            EMPLOYEE: `employee`
        }
    }

    constructor(data) {
        for (let key in data) {
            this[key] = data[key];
        }
        if (typeof this.name === `object`) {
            this.name = tr(this.name);
        }
    }
}
