<template>
    <div class="e-container" :style="{ maxWidth: maxWidth + `px` }">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: `e-container`,
        props: {
            maxWidth: {type: Number, default: 1140 }
        }
    }
</script>

<style lang="scss" scoped>
    .e-container {
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
    }

    @media(max-width: 600px) {
        .e-container {
            padding-left: 0;
            padding-right: 0;
        }
    }
</style>
