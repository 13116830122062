<template>
    <div class="website-page-container" :class="[containerClass, { 'has-virtual-keyboard': virtualKeyboard }]">
        <template v-if="!isEmbed">
            <kiosk-navigation v-if="isKioskLayout" position="top"/>
            <website-menu v-else fixed/>
        </template>
        <div class="website-page" :class="[pageClass, { 'no-menu-padding': noMenuPadding, 'centered': centered }]">
            <slot></slot>
        </div>
        <template v-if="!isEmbed">
<!--            v-show is for debug only -->
            <div v-if="isKioskLayout">
                <b-img fluid height="350" src="https://eyeindevstorage.blob.core.windows.net/eye-intelligence/cms/medias/hopitallakeshore/bottom-banner-image.png"/>
<!--                <kiosk-navigation  position="bottom"/>-->
            </div>
            <website-footer v-else-if="!noFooter"/>
        </template>

        <chat-button v-if="!isKioskLayout"/>

        <slot name="keyboard"></slot>
    </div>
</template>

<script>
    import WebsiteFooter from "@/layout/website-footer.vue";
    import WebsiteMenu from "@/layout/website-menu.vue";
    import ChatButton from "../components/chat-button.vue";
    import KioskNavigation from "./kiosk-navigation.vue";
    export default {
        name: `website-page`,
        components: {ChatButton, KioskNavigation, WebsiteFooter, WebsiteMenu},
        props: {
            containerClass: {type: String, default: ``},
            pageClass: {type: String, default: ``},
            virtualKeyboard: {type: Boolean},
            centered: {type: Boolean},
            noMenuPadding: {type: Boolean},
            noFooter: {type: Boolean},
            loading: {type: Boolean}
        },
        computed: {
            isEmbed() {
                return this.$route.query.embed !== undefined;
            }
        }
    }
</script>

<style lang="scss" scoped>
.website-page-container {
    position: relative;
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;

    .website-page {
        flex: 1;
        display: flex;
        flex-direction: column;
        width: 100%;
        padding-top: var(--menu-height);

        &.no-menu-padding {
            padding-top: 0;
        }

        &.centered {
            align-items: center;
            justify-content: center;
        }
    }

}
</style>
