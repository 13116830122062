import Vue from "vue";
import "../vue-components/helpers/vue-filters.js";
import "../vue-components/helpers/vue-directives.js";
import "../vue-components/helpers/vue-mixin.js"

Vue.mixin({
    computed: {
        websiteId() {
            return this.$store.state.website.id;
        },
        cms() {
            return this.$store.state.cms;
        },
        website() {
            return this.$store.state.website;
        },
        page() {
            return this.$store.state.page;
        },
        isKioskLayout() {
            return this.$store.state.isKioskLayout;
        },
        fontAwesomePrefix() {
            return this.$store.state.website.branding.fontAwesome.prefix;
        }
    }
});

export default {};
