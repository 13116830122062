<template>
    <dialog class="virtual-keyboard" :class="['theme-' + theme, { 'visible': visible }]">
        <div :class="keyboardClass" :disabled="disabled"></div>
    </dialog>
</template>

<script>
import Keyboard from "simple-keyboard";
import "simple-keyboard/build/css/index.css";

export default {
    name: `virtual-keyboard`,
    props: {
        keyboardClass: { default: `simple-keyboard`, type: String },
        disabled: { type: Boolean },
        maxLength: { type: Number },
        value: { type: String },
        theme: { type: String, default: `dark` } // light or dark
    },
    computed: {
        input: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit(`input`, value);
                this.keyboard.setInput(value);
            }
        }
    },
    data() {
        return {
            keyboard: null,
            visible: true
        }
    },
    mounted() {
        this.keyboard = new Keyboard(this.keyboardClass, {
            onChange: this.onChange,
            onKeyPress: this.onKeyPress,
            maxLength: this.maxLength,
            layout: {
                default: [
                    `1 2 3 4 5 6 7 8 9 0 {bksp}`,
                    `q w e r t y u i o p -`,
                    `a s d f g h j k l {enter}`,
                    `z x c v b n m `,
                    `{space}`
                ]
            },
            display: {
                "{escape}": `Esc`,
                "{tab}": `⇥`,
                "{bksp}": `⌫`,
                "{shift}": `⇧`,
                "{space}": `␣`,
                "{enter}": `↵`,
                "{capslock}": `⇪`,
            }
        });
    },
    methods: {
        onChange(input) {
            this.$emit(`change`, input);
        },
        onKeyPress(button) {
            this.$emit(`keyPress`, button);

            /**
             * If you want to handle the shift and caps lock buttons
             */
            if (button === `{shift}` || button === `{lock}`) this.handleShift();
        },
        handleShift() {
            let currentLayout = this.keyboard.options.layoutName;
            let shiftToggle = currentLayout === `default` ? `shift` : `default`;

            this.keyboard.setOptions({
                layoutName: shiftToggle
            });
        },
        clear() {
            this.keyboard.clearInput();
        }
    }
};
</script>

<style lang="scss" scoped>
.virtual-keyboard {
    position: fixed;
    top: calc(50% + 200px);
    z-index: 2000;
    width: 1080px;
    max-width: calc(100% - 40px);
    left: calc(50% - 540px);
    margin: 0;
    border-radius: 10px;
    box-shadow: 0 20px 50px rgba(black, 0.2);
    background: #ececec;
    padding: 10px;
    display: block;
    border: 2px solid #bbb;

    ::v-deep .hg-button {
        height: 55px;

        &.hg-button-space {
            padding-top: 0;
            font-size: 25pt;
        }
        &.hg-button-enter {
            padding-top: 5px;
            font-size: 25pt;
        }
    }

    &.theme-dark {
        background: #222;
        border-color: #111;

        .simple-keyboard {
            background: inherit;

            ::v-deep .hg-button {
                background: #666;
                border-bottom-color: #444;
                color: #eee;
            }
        }
    }
}

@media(max-width: 1080px) {
    .virtual-keyboard {
        left: 20px;
    }
}

@media(max-height: 1200px) {
    .virtual-keyboard {
        top: auto;
        bottom: 20px;
    }
}
</style>
