<template>
    <e-navigation-bar class="sub-navigation-bar" not-fixed>
        <template #start>
            <router-link :to="backTo">
                <i class="fa-chevron-left mr-1" :class="fontAwesomePrefix"/>
                {{backText|tr}}
            </router-link>
        </template>

        <template #title>
            <slot name="title">
                <h1 v-if="isKioskLayout" class="my-4">{{title|tr}}</h1>
                <span v-else class="my-2 f-700">{{title|tr}}</span>
            </slot>
        </template>
    </e-navigation-bar>
</template>

<script>
import ENavigationBar from "../../vue-components/components/e-navigation-bar.vue";

export default {
    name: `sub-navigation-bar`,
    components: {ENavigationBar},
    props: {
        title: {type: String},
        backTo: {type: String},
        backText: {type: String}
    }
}
</script>

<style lang="scss" scoped>
.sub-navigation-bar {
    a {
        padding: 10px;
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center;
        text-decoration: none !important;

        &:hover {
            background-color: rgba(black, 0.1);
        }
    }
}
</style>
