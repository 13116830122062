import MapMathHelper from "./MapMathHelper.js";

export default class Vector {
    constructor(x, y) {
        this.x = x;
        this.y = y;
    }

    invertY() {
        this.y = this.y * -1;
    }

    normalize() {
        return new Vector(this.x / this.getLength(), this.y / this.getLength());
    }

    getLength() {
        return Math.sqrt(this.x**2 + this.y**2);
    }

    getAngle() {
        return MapMathHelper.normalizeAngle(Math.round(Math.atan2(this.y, this.x) * 180 / Math.PI), true);
    }

    getDiffAngle(vector) {
        return MapMathHelper.normalizeAngle(Math.round((Math.atan2(vector.y, vector.x) - Math.atan2(this.y, this.x)) * 180 / Math.PI));
    }
}
