import MapInstructions from "./MapInstructions.js";

export default class WayfinderHelper {
    constructor(map, floor) {
        this.map = map;
        this.floor = floor;

        this.nodes = {};
        for (let item of map.items) {
            let node = {};
            for (let link of item.links) {
                node[link.getOtherItem(item).index] = link.distance;
            }
            this.nodes[item.index] = node;
        }
        // console.log(this.nodes);
    }

    /**
     * Find closest navigation path
     * @param startPoint {MapItem}
     * @param endPoint {MapItem}
     */
    findClosestPath(startPoint, endPoint) {
        if (startPoint.links.length === 0 || endPoint.links.length === 0) {
            // TODO LATER: find closest nav point on floor
            console.log(startPoint, endPoint);
            console.error(`Not reachable`);
            return [];
        }

        const startNode = this.nodes[startPoint.index];
        const endNode = this.nodes[endPoint.index];

        let navLinks = [];
        let navItems = [endPoint];
        let distances = Object.assign({}, startNode);
        distances[endNode.index] = Infinity;
        let parents = {};
        let history = [];

        let node = this.closestItem(startNode, history);

        let index = 0;
        while (node) {
            let distance = distances[node];
            if (!distance) {
                distance = 0;
            }
            let children = this.nodes[node];
            for (let n in children) {
                let newDistance = distance + children[n];
                if (!distances[n] || distances[n] > newDistance) {
                    distances[n] = newDistance;
                    parents[n] = node;
                }
            }
            history.push(node);
            node = this.closestItem(distances, history);

            if (++index > 500) {
                console.error(`Query above 500, aborting...`);
                node = null;
                break;
            }
        }

        let parent = parents[endPoint.index];
        let lastNavItem = endPoint;
        // From end to start
        while (parent) {
            const navItem = this.map.items.find(item => item.index === parent);
            navItems.push(navItem);

            const navLink = this.map.links.find(link => link.isLinkBetween(lastNavItem, navItem))
            navLinks.push(navLink);
            navLink.setStartPoint(lastNavItem);

            lastNavItem = navItem;
            parent = parents[parent];
        }

        // add link with start point
        navLinks.push(this.map.links.find(link => link.isLinkBetween(lastNavItem, startPoint)));
        navItems.push(startPoint);
        navLinks.reverse();
        navItems.reverse();

        let navInstructions = [];
        for (let i = 0; i < navItems.length; i++) {
            const previousItem = i > 0 ? navItems[i - 1] : null;
            const currentItem = navItems[i];
            const nextItem = i < navItems.length - 1 ? navItems[i + 1] : null;
            const navInstruction = new MapInstructions(previousItem, currentItem, nextItem);
            if (!navInstruction.link || navInstruction.link.visible) {
                navInstructions.push(navInstruction);
            }
        }

        for (let i = 1; i < navInstructions.length - 1; i++) {
            const navInstruction = navInstructions[i];
            const nextNavInstruction = navInstructions[i + 1];
            if (navInstruction.direction === `forward` && nextNavInstruction.distance < 10) {
                nextNavInstruction.mergeWith(navInstruction);
                navInstructions.removeItemAtIndex(i);
                i--;
            }
        }
        if (navInstructions.length > 0) {
            navInstructions[navInstructions.length - 1].setArrival();
        }

        const result = {
            distance: distances[endPoint.index],
            start: startPoint,
            end: endPoint,
            items: navItems,
            instructions: navInstructions,
            links: navLinks
        };

        // console.log(result.distance, navInstructions, navItems, navLinks);

        return result;
    }

    closestItem(distances, history) {
        let minDistance = Infinity;
        let closestNode = null;
        for (let node in distances) {
            if (!history.includes(node) && distances[node] < minDistance) {
                minDistance = distances[node];
                closestNode = node;
            }
        }
        return closestNode;
    }
}
