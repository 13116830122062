<template>
    <g v-if="item" :visibility="isVisible ? 'visible': 'hidden'" class="map-item" :z-index="item.zIndex" :class="['type-' + item.type, { 'item-selected': selected }, { 'active': active }]" :transform="getTransform">
        <circle @mousedown.self="$emit('selected', item)" class="selector" :class="{ 'hidden': !currentMap.style.iconCircleVisible }" :r="iconSize * 0.85"/>
        <image class="icon" @mousedown.self="$emit('selected', item)" :width="iconSize" :height="iconSize" :x="-iconSize / 2" :y="-iconSize / 2"
               :href="getPublicIcon(item.type)"/>

        <text v-if="isLabelVisible" class="map-label" :y="scaleUI(32)" :style="labelStyle" :font-size="scaleUI(24)" text-anchor="middle" paint-order="stroke" dominant-baseline="hanging" >
            <template v-if="showMapDebug">[{{item.index}}]</template>
            <template v-if="item.name">{{item.name}}</template>
            <template v-else-if="item.type !== `nav-point`">New {{item.type}}</template>
        </text>
    </g>
</template>

<script>
import MapHelper from "@/helpers/maps/MapHelper.js";
import MapMixin from "@/helpers/maps/map-mixin.js";

export default {
    name: `map-item-point`,
    mixins: [MapMixin],
    props: {
        item: { type: Object },
        floor: { type: Object },
        zoom: { type: Number },
        layers: { type: Object },
        active: { type: Boolean },
        selected: { type: Boolean },
        showStats: { type: Boolean },
        rangeDisplay: { type: String, default: `long` },
        apStats: { type: Object }
    },
    data() {
        return {
            MapHelper
        }
    },
    computed: {
        iconSize() {
            return this.scaleUI(this.currentMap.style.iconSize);
        },
        getTransform() {
            return `translate(${this.pxToMeters(this.item.x)} ${this.pxToMeters(this.item.y)})`;
        },
        containerStyle() {
            return {
                zIndex: this.item.zIndex
            }
        },
        labelStyle() {
            return {
                strokeWidth: this.scaleUI(6)
            }
        },
        isLabelVisible() {
            if (this.showMapDebug) {
                return true;
            }
            switch (this.item.labelVisibility) {
                case `auto`:
                    return this.item.category === `location` || this.active;
                case `always`:
                    return true;
                case `active`:
                    return this.active;
                    // including never
                default:
                    return false;
            }
        },
        isVisible() {
            if (this.showMapDebug) {
                return true;
            }

            switch (this.item.visibility) {
                case `auto`:
                    return this.item.type !== `nav-point` && this.item.type !== `directions`;
                case `always`:
                    return true;
                case `active`:
                    return this.active;
                    // including never
                default:
                    return false;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.map-item {
    z-index: 1;
    cursor: pointer;
    .icon {
        pointer-events: none;
    }

    circle.selector {
        fill: rgba(255, 255, 255, 0.2);
        stroke: rgba(255, 255, 255, 0.1);

        &.hidden {
            fill: transparent;
            stroke: transparent;
        }
        stroke-width: 0.1px;

        &:hover {
            fill: rgba(var(--selection-color), 0.3) !important;
            stroke: rgb(var(--selection-color)) !important;
        }
    }

    &:hover {
        .icon {
            transform: scale(1.2);
        }
    }

    &.active {
        .icon {
            transform: scale(1.5);
        }
    }

    .map-label {
        z-index: 2;
        alignment-baseline: middle;
        user-select: none;
        pointer-events: none;
        fill: #333;
        font-weight: bold;
        text-align: left;
        stroke: white;
        //text-shadow: 2px 2px 5px black;

        &.auto-hide {
            //display: none;
        }
    }
}
</style>
