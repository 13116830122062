<template>
    <b-list-group-item class="search-list-item interactive" @click="onClick">
        <div class="main-properties">
            <span class="name">{{item.name|tr}}</span>
        </div>
    </b-list-group-item>
</template>

<script>
export default {
    name: `search-list-item`,
    props: {
        item: {type: Object, required: true}
    },
    methods: {
        onClick() {
            this.$emit(`click`, this.item);
        }
    }
}
</script>

<style lang="scss" scoped>
.search-list-item {
    position: relative;
    text-align: left;
    background-color: #fff;
    border: 2px solid rgba(0, 0, 0, .125);
    padding: 10px 20px;
    font-weight: 500;
    font-size: 18pt;
    display: flex;

    & + .list-group-item {
        border-top: none;
    }

    .logo {
        width: 250px;
        height: 100px;
        object-fit: contain;
        margin-left: -20px;
    }
}

</style>
