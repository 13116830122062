<template>
    <div v-if="map && floor && floor.id" class="map-container" :class="[{ 'editable': editable }, 'theme-' + map.style.theme, 'skin-' + map.style.skin]">
        <e-interactive-svg ref="svg" :real-width="floor.real_width" :real-height="floor.real_height"
                           :show-scale-legend="showScaleLegend" :background-image="floor.image_full_path" :opacity="map.style.backgroundOpacity"
                           :zoom.sync="zoomDynamic" @item-moved="$emit(`item-moved`, $event)" :item-selected="itemSelected" @unselect="$emit('unselect')" @mousemove="$emit('mousemove', $event)">
            <slot></slot>

            <template #pointer="coordinates">
                <slot name="pointer" v-bind="coordinates"></slot>
            </template>
        </e-interactive-svg>

        <slot name="controls"></slot>

        <aside v-if="!fullscreen && showControls" class="map-controls-panel">
            <div class="map-control">
                <span class="floating-label">Zoom in</span>
                <b-btn variant="variant" @click="$refs.svg.applyZoom(1)">
                    <i class="fas fa-magnifying-glass-plus"/>
                </b-btn>
            </div>
            <div class="map-control">
                <span class="floating-label">Zoom out</span>
                <b-btn variant="variant" @click="$refs.svg.applyZoom(-1)">
                    <i class="fas fa-magnifying-glass-minus"/>
                </b-btn>
            </div>
        </aside>

        <div v-if="$slots.legend" class="map-legend">
            <slot name="legend"></slot>
        </div>
    </div>
</template>

<script>
import EInteractiveSvg from "../../../vue-components/components/e-interactive-svg";
import MapMixin from "@/helpers/maps/map-mixin.js";

export default {
    name: `map-container`,
    mixins: [MapMixin],
    components: {EInteractiveSvg},
    props: {
        itemSelected: { type: Object },
        map: { type: Object },
        floor: { type: Object },
        editable: { type: Boolean },
        fullscreen: { type: Boolean },
        showControls: { type: Boolean },
        showScaleLegend: {type: Boolean},
        zoom: { type: [Number] }

    },
    data() {
        return {
            circle: {
                x: 0,
                y: 0
            },
            internalZoom: 1,
            rotation: 50
        }
    },
    computed: {
        zoomDynamic: {
            get() {
                return this.zoom;
            },
            set(value) {
                this.internalZoom = value;
                this.$emit(`update:zoom`, value);
            }
        }
    },
    methods: {
        createItemFromTemplate(template, event) {
            this.$emit(`item-created`, template, this.$refs.svg.mouseEventToPoint(event));
        }
    }
}
</script>

<style lang="scss">
.map-container {
    position: relative;
    width: 100%;
    height: 100%;
    min-width: 300px;
    min-height: 300px;
    //background-image: linear-gradient(to top, rgb(127, 129, 82), rgb(36, 143, 171));
    background: #eee;
    overflow: hidden;

    --map-primary-color: #1b8fad;
    --map-secondary-color: purple;

    .e-interactive-svg {
        min-height: calc(80vh);
    }

    .map-legend {
        position: absolute;
        z-index: 1;
        bottom: 50px;
        left: 0;
    }

    .map-controls-panel {
        position: absolute;
        top: 0;
        right: 0;
        padding: 5px;
        text-align: right;

        &:hover span {
            opacity: 1;
            margin-right: 0;
        }

        .floating-label {
            display: inline-block;
            opacity: 0;
            margin-right: 10px;
            font-weight: 500;
            text-align: right;
            transition: opacity 250ms, margin-right 250ms;
            pointer-events: none;
            color: black;
            text-shadow: 2px 2px 5px white;
        }

        .btn {
            display: inline-block;
            width: 50px;
            height: 50px;
            font-size: x-large;
            margin: 5px;
            background-color: #fff;
            color: #333;

            &:hover {
                background-color: rgb(36, 94, 117);
                color: white;
            }

            &:active {
                background-color: rgb(36, 94, 117);
                color: white;
            }

            &:focus, &:visited {
                background-color: rgb(36, 94, 117);
                color: white;
            }

            &.btn-toggled {
                color: white;
                background-color: #369676;
            }
        }
    }

    rect {
        fill: rgba(53, 51, 71, 0.8);

        &.shelf {
            fill: rgba(199, 199, 199, 0.4);
            stroke: rgba(0, 0, 0, 0.4);
            stroke-width: 0.1;
        }
    }

    .zone {
        z-index: 1;

        .resizable-content,
        rect {
            fill: none;
            stroke: rgba(233, 30, 99, 0.5);
            stroke-width: 0.1;
        }

        text {
            fill: rgba(233, 30, 99, 0.8);
            text-anchor: start;
            alignment-baseline: before-edge !important;
            font-weight: 500;
        }

        &:hover {
            .resizable-content,
            rect {
                fill: rgba(233, 30, 99, 0.2);
                stroke: rgba(233, 30, 99, 0.8);
            }

            text {
                fill: rgba(233, 30, 99, 1);
            }
        }
    }

    line.map-scale {
        fill: white;
        stroke-width: 0.15;
        box-shadow: 2px 2px 5px black;
    }

    text.map-scale {
        user-select: none;
        fill: white;
        text-align: right;
        font-weight: 500;
        text-shadow: 2px 2px 5px black;
    }

    .map-dot {
        fill: rgba(255, 87, 34, 0.4);
    }

    .item {
        z-index: 2;
    }

    path {
        fill: none;

        &.man {
            stroke: #3f51b5;
        }

        &.woman {
            stroke: #e91e63;
        }

        &.unknown-gender {
            stroke: #795548;
        }
    }

    @keyframes spin {
        100% {
            transform: rotate(360deg);
        }
    }

    .radius {
        fill: rgba(black, 0.06);
        stroke: rgba(black, 0.3);
        stroke-width: .1;
        pointer-events: none;
        stroke-dasharray: 0.3, 0.3;

        &.short-range {
            fill: rgba(#559a2c, 0.15);
            stroke: rgba(#559A2C, 0.5);
            stroke-dasharray: 0.3, 0.3;
        }
    }

    --selection-color: 255, 165, 0;

    .overlay-link-items {
        stroke: rgba(var(--selection-color), 0.9);
        stroke-linecap: round;
        stroke-dasharray: 2, 2;
        pointer-events: none;
    }

    .item-selected {
        &.type-shelf,
        &.zone {
            rect {
                fill: rgba(255, 210, 4, 0.2);
                stroke: rgb(var(--selection-color));
            }

            text {
                fill: #cf8600;
            }
        }

        circle.selector {
            fill: rgba(255, 182, 0, 0.3) !important;
            stroke: rgb(var(--selection-color)) !important;
        }

        .radius {
            fill: rgba(255, 182, 0, 0.1);
            stroke: rgba(255, 165, 0, 0.5);
            /*stroke-width: .1;*/
            /*pointer-events: none;*/

            &.short-range {
                fill: rgba(255, 182, 0, 0.2);
                stroke: var(--selection-color);
            }
        }
    }

    &:not(.show-labels) {
        .map-item:hover {
            .map-label {
                display: block;
            }
        }
    }

    &.show-labels {
        .map-label {
            display: block;
        }
    }
}

.kiosk-layout {
    .e-interactive-svg {
        min-height: 70vh;
    }
}
</style>
