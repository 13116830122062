<template>
    <div id="app" :class="{ 'kiosk-layout': isKioskLayout, 'app-ready': $store.state.applicationReady }">
        <keep-alive>
            <router-view v-if="$store.state.applicationReady" :key="$route.fullPath"/>
        </keep-alive>
    </div>
</template>

<script>
import WebsiteConfig from "@/helpers/WebsiteConfig.js";
import {getLanguage, translate} from "../vue-components/helpers/i18n"
// import Network from "../vue-components/helpers/Network.js";
import moment from "moment"
import website from "./assets/json/website.json"
import cmsTypes from "./assets/json/cms-types.json"
import cmsEmployees from "./assets/json/cms-employees.json"
import cmsDepartments from "./assets/json/cms-departments.json"
import cmsRooms from "./assets/json/cms-rooms.json"
import CMS from "./helpers/cms/CMS.js";

export default {
    name: `App`,
    metaInfo: {
        title: translate(`My Home Website|Mon site web à moi`),
        titleTemplate: `%s | ${process.env.VUE_APP_NAME}`
    },
    data() {
        return {
            ready: true // True by default except on the login vue
        };
    },
    computed: {
        currentPage() {
            for (let page of this.website.pages) {
                if (page.alias.en === this.$route.params.alias || page.alias.fr === this.$route.params.alias) {
                    return page;
                }
            }
            return null;
        },
        isUserConnected() {
            return this.ready === true && this.$store.getters.isAuthenticated;
        },
        isEmbed() {
            return this.$route.query.embed === `true`;
        }
    },
    created() {
        // if (this.$route.query.website_id) {
        //     this.$store.state.websiteId = this.$route.query.website_id;
        //     localStorage.setItem(`website_id`, this.$store.state.websiteId);
        //     this.$store.state.applicationReady = true;
        // } else if (localStorage.getItem(`website_id`)) {
        //     this.$store.state.websiteId = localStorage.getItem(`website_id`);
        //     this.$store.state.applicationReady = true;
        // } else {
        // Network.get(`/api/websites/by-alias/${this.$store.state.websiteAlias}`)
        //     .then(resp => {
        //         window.websiteConfig = new WebsiteConfig(resp.data);
        //         this.$store.commit(`setWebsite`, window.websiteConfig);
        //         this.$store.commit(`setWebsite`, resp.data);
        //         this.$store.state.applicationReady = true;
        //     });
        // }

        if (this.$route.query.display === `kiosk` || localStorage.getItem(`kiosk`) === `yes`) {
            this.$store.state.isKioskLayout = true;
            localStorage.setItem(`kiosk`, `yes`);
        }

        try {
            // Window for easy debugging
            window.websiteConfig = new WebsiteConfig(website);
            this.$store.commit(`setWebsite`, window.websiteConfig);

            window.cms = new CMS();
            window.cms.initTypes(cmsTypes);
            window.cms.addItems(cmsEmployees);
            window.cms.addItems(cmsDepartments);
            window.cms.addItems(cmsRooms);
            window.cms.updateDependencies();

            this.$store.commit(`setCms`, window.cms);
            this.$store.state.applicationReady = true;
        } catch (e) {
            console.error(e);
            this.$router.push(`/error`);
        }

        // this.$store.commit(`setWebpSupport`);
        this.onWindowResize();
        window.addEventListener(`resize`, this.onWindowResize);

        if (this.$route.query && this.$route.query.lang) {
            this.$store.commit(`setLanguage`, this.$route.query.lang);
        }
        const language = getLanguage();
        moment.locale(language);
    },
    mounted() {

    },
    methods: {
        onWindowResize() {
            this.$store.state.isMobileLayout = window.isMobileLayout;
            this.$store.state.isSmallLayout = window.isSmallLayout;
            this.$store.state.isMobileOrTabletLayout = window.isMobileOrTabletLayout;
            this.$store.state.isLargeLayout = window.isLargeLayout;
            this.$store.state.isExtraLargeLayout = window.isExtraLargeLayout;
        }
    }
}
</script>

<style lang="scss">
@import "assets/style/global.scss";

html {
    height: var(--app-height);
}

body {
    width: 100vw;
    height: 100vh;
    height: var(--app-height);
    padding: 0;
    font-family: var(--body-font);
    font-size: 13pt;
    position: relative;
}

#app {
    //font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    width: 100%;
    height: var(--app-height);
}
</style>
