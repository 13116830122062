<template>
    <picture class="e-img">
        <source :srcset="optimizedSrc" type="image/webp"/>
        <source :srcset="imgSrc" :type="mimeType"/>
        <component :is="componentTag" :src="imgSrc" :alt="alt" :thumbnail="thumbnail" :block="block" :center="center"
                   :blank-color="blankColor" @load="$emit('load')"
                   :fluid="fluid" :fluid-grow="fluidGrow" :rounded="rounded" :width="width" :height="height" :class="[imgClass, portraitMode ? 'portrait' : 'landscape']"></component>
    </picture>
</template>

<script>
    /*
    Allow to easily offer webp optimized image by providing a fallback src to a common image format like png or jpg

     */

    export default {
        name: `e-img`,
        props: {
            src: { type: String, required: true }, // Relative path of the image without require (../assets/
            defaultSrc: { type: String }, // Relative path of the image without require (../assets/
            alt: { type: String },
            imgClass: { type: String },
            type: { type: String, default: `webp` }, // Only1 Webp for now
            width: { type: [String, Number] },
            height: { type: [String, Number] },
            fluid: { type: Boolean },
            fluidGrow: { type: Boolean },
            block: { type: Boolean },
            center: { type: Boolean },
            thumbnail: { type: Boolean },
            blankColor: { type: String },
            useThumbnail: { type: Boolean },
            rounded: { type: Boolean },
            centered: { type: Boolean },
            lazy: { type: Boolean },
            portraitMode: { type: Boolean, default: false }
        },
        computed: {
            componentTag() {
                return this.lazy ? `b-img-lazy` : `b-img`;
            },
            fileExtension() {
                let names = this.src.split(`.`);
                if (names.length > 1) {
                    return names[names.length - 1].toLowerCase();
                } else {
                    return ``;
                }
            },
            mimeType() {
                const mimes = {
                    jpg: `image/jpeg`,
                    bmp: `image/bitmap`,
                    png: `image/png`,
                    svg: `image/svg+xml`,
                    gif: `image/gif`
                };
                if (!mimes.hasOwnProperty(this.fileExtension)) {
                    return `image/jpeg`;
                } else {
                    return mimes[this.fileExtension];
                }
            },
            _src() {
                if (isStringEmpty(this.src) && this.defaultSrc) {
                    return this.defaultSrc;
                } else {
                    return this.src.replace(`../assets/`, ``);
                }
            },
            imgSrc() {
                if (this._src.startsWith(`https://`) || this._src.startsWith(`data:image`)) {
                    return this._src;
                } else {
                    return require(`../../src/assets/${this._src}`);
                }
            },
            optimizedSrc() {
                let optimizedSrc = this._src.replace(/(jpg|jpeg|gif|bmp|png)/, this.type);
                if (this.useThumbnail) {
                    optimizedSrc = this._src.replace(/\.(jpg|jpeg|gif|bmp|png)/, `-thumbnail.` + this.type);
                }

                if (this._src.indexOf(`https://`) >= 0) {
                    return optimizedSrc;
                } else {
                    try {
                        return require(`../../src/assets/${optimizedSrc}`);
                    } catch (e) {
                        return this.imgSrc;
                    }
                }
            }
        }
    };
</script>

<style lang="scss" scoped>
    .e-img {
        .landscape {
            object-fit: cover !important;
        }

        .portrait {
            object-fit: contain !important;
        }
    }
</style>
