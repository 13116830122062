<template>
    <div></div>
</template>

<script>
export default {
    name: `home`,
    activated() {
        this.$router.push(`/map`);
    },
    mounted() {
        this.$router.push(`/map`);
    }
}
</script>

<style scoped lang="scss">

</style>
