<template>
    <div class="chat-button">
        <a target="_blank" href="https://chat.eyein.media/chat/standalone/367B20D1-2EA8-4B6E-9221-1ED741C30763">
            <i class="fas fa-messages"/>
        </a>
    </div>
</template>

<script>
    export default {
        name: `chat-button`
    }
</script>

<style lang="scss" scoped>
.chat-button {
    position: fixed;
    right: 5px;
    bottom: 5px;
    z-index: 10;

    a {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        background-color: var(--primary-color);
        color: white;
        text-decoration: none !important;
    }
}
</style>
