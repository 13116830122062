export default class WebsiteConfig {
    constructor(data) {
        this.elements = {};
        try {
            this.data = this.mergeResponsiveStyle(data);
        } catch (e) {
            console.warn(`Could not merge Responsive Style`, e);
            this.data = data;
        }

        this.settings = data.settings;
        this.legal = data.legal;
        this.branding = data.branding;
        this.body = data.body;
        this.menu = data.menu;
        this.pages = data.pages;
        this.footer = data.footer;
        this.style = data.style;
        this.type = data.type; // kiosk | website

        this.elements.root = document.querySelector(`:root`);

        this.applyStyle();
    }

    applyStyle() {
        this.elements.favicon = document.getElementById(`favicon`);
        if (this.elements.favicon) {
            this.elements.favicon.href = this.branding.favicon;
        }

        let customFonts = this.branding.fonts.customFonts;
        if (customFonts) {
            for (let font of customFonts) {
                let link = document.createElement(`link`);
                link.setAttribute(`rel`, `stylesheet`);
                link.setAttribute(`type`, `text/css`);
                link.setAttribute(`href`, font);
                document.head.appendChild(link);
            }
        }

        if (this.style.customCSS) {
            let link = document.createElement(`style`);
            link.innerHTML = this.style.customCSS;
            document.body.appendChild(link);
        }

        /*
        CSS Custom Variables
         */
        // Fonts
        this.setCssProperty(`--title-font`, this.branding.fonts.titles.fontFamily);
        this.setCssProperty(`--body-font`, this.branding.fonts.body.fontFamily);

        // Color Scheme
        this.setCssProperty(`--primary-color`, this.branding.colors.primary);
        this.setCssProperty(`--secondary-color`, this.branding.colors.secondary);
        this.setCssProperty(`--info-color`, this.branding.colors.info);
        this.setCssProperty(`--warning-color`, this.branding.colors.warning);
        this.setCssProperty(`--danger-color`, this.branding.colors.danger);
        this.setCssProperty(`--light-color`, this.branding.colors.light);
        this.setCssProperty(`--dark-color`, this.branding.colors.dark);

        // Menu
        this.setCssProperty(`--menu-height`, this.menu.design.navLinks.style.height);

        if (this.menu.design.customBorder.visible) {
            this.setCssProperty(`--menu-border-display`, `block`);
            this.setCssProperty(`--menu-border-background`, this.menu.design.customBorder.background);
            this.setCssProperty(`--menu-border-height`, this.menu.design.customBorder.height);
        } else {
            this.setCssProperty(`--menu-border-display`, `none`);
        }

        this.setCssProperty(`--menu-item-margin`, this.menu.design.navLinks.style.margin);
        this.setCssProperty(`--menu-item-padding`, this.menu.design.navLinks.style.padding);
        this.setCssProperty(`--menu-item-border-radius`, this.menu.design.navLinks.style.borderRadius);
        this.setCssProperty(`--menu-item-font-weight`, this.menu.design.navLinks.style.fontWeight);
        this.setCssProperty(`--menu-item-color`, this.menu.design.navLinks.style.color);
        this.setCssProperty(`--menu-item-background`, this.menu.design.navLinks.style.background);
        this.setCssProperty(`--menu-item-hover-color`, this.menu.design.navLinks.hover.style.color);
        this.setCssProperty(`--menu-item-hover-background`, this.menu.design.navLinks.hover.style.background);
        this.setCssProperty(`--menu-item-active-color`, this.menu.design.navLinks.active.style.color);
        this.setCssProperty(`--menu-item-active-background`, this.menu.design.navLinks.active.style.background);

        // Body
        this.setCssProperty(`--body-color`, this.body.design.style.color);
        this.setCssProperty(`--body-background`, this.body.design.style.background);

        if (this.body.page.mainDivider.visible) {
            this.setCssProperty(`--page-border-display`, `block`);
            this.setCssProperty(`--page-border-background`, this.body.page.mainDivider.background);
            this.setCssProperty(`--page-border-height`, this.body.page.mainDivider.height);
        } else {
            this.setCssProperty(`--page-border-display`, `none`);
        }

        // Cards
        this.setCssProperty(`--cards-box-shadow`, this.body.cards.style.boxShadow);
        this.setCssProperty(`--cards-border-radius`, this.body.cards.style.borderRadius);

        // Buttons
        this.setCssProperty(`--buttons-font-weight`, this.branding.buttons.style.fontWeight);
    }

    mergeResponsiveStyle(style) {
        for (let key in style) {
            if (key === `style`) {
                style[key] = this.mergeStyleProperties(style[key]);
            } else if (typeof style[key] === `object`) {
                style[key] = this.mergeResponsiveStyle(style[key]);
            }
        }
        return style;
    }

    mergeStyleProperties(style) {
        let responsiveStyle = {};

        for (let key in style) {
            responsiveStyle[key] = style[key];
        }

        // Override properties based on the layout
        const mobileStyle = style.mobile;
        if (window.isMobileLayout && mobileStyle) {
            for (let key in mobileStyle) {
                responsiveStyle[key] = mobileStyle[key];
            }
        }

        const tabletStyle = style.tablet;
        if (window.isTabletLayout && tabletStyle) {
            for (let key in tabletStyle) {
                responsiveStyle[key] = tabletStyle[key];
            }
        }

        const desktopStyle = style.desktop;
        if (window.isMobileLayout && desktopStyle) {
            for (let key in desktopStyle) {
                responsiveStyle[key] = desktopStyle[key];
            }
        }
        return responsiveStyle;
    }

    setCssProperty(name, value) {
        if (value && value !== ``) {
            this.elements.root.style.setProperty(name, value);
        }
    }
}
